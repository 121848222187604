const admin = ['ROLE_ADMIN'];
const system = ['ROLE_SYSTEM'];
const cadastros = ['ROLE_MENU_CRUD_MANAGER', 'ROLE_ADMIN'];
const ecac = ['ROLE_MENU_ECAC', 'ROLE_ADMIN'];
const ecacMensagens = ['ROLE_MENU_ECAC_MENSAGENS', 'ROLE_ADMIN'];
const esocial = ['ROLE_MENU_ESOCIAL', 'ROLE_ADMIN'];
const index = ['ROLE_MENU_INTELLIGENCE', 'ROLE_ADMIN'];
const certidoes = ['ROLE_MENU_CERTIDOES', 'ROLE_ADMIN'];
const pisCofins = ['ROLE_MENU_PISCOFINS', 'ROLE_ADMIN'];
const crf = ['ROLE_CRF_MANAGER', 'ROLE_ADMIN'];
const totalizadores = ['ROLE_MENU_TOTALIZADORES', 'ROLE_ADMIN'];
const downloads = ['ROLE_MENU_DOWNLOADS', 'ROLE_ADMIN'];
const filesRead = ['ROLE_MENU_FILES_READ', 'ROLE_ADMIN'];
const filesUpload = ['ROLE_MENU_FILE_UPLOAD', 'ROLE_ADMIN'];
const afastadosInativos = ['ROLE_MENU_AFASTADOS_INATIVOS', 'ROLE_ADMIN'];
const cessao = ['ROLE_MENU_CESSAO', 'ROLE_ADMIN'];
const cbo = ['ROLE_MENU_CBO', 'ROLE_ADMIN'];

export {
  admin,
  system,
  cadastros,
  ecac,
  ecacMensagens,
  esocial,
  index,
  certidoes,
  pisCofins,
  crf,
  totalizadores,
  downloads,
  filesRead,
  filesUpload,
  afastadosInativos,
  cessao,
  cbo,
};
