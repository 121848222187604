import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import pt from 'vuetify/lib/locale/pt';
// import '../design/app.scss';

const isEmbeded = process.env.VUE_APP_EMBEDED;
let css = null;

if (isEmbeded === 'true') {
  css = require('../design/appEmbed.scss');
} else {
  css = require('../design/app.scss');
}

Vue.use(Vuetify);

const agnes = {
  primary: '#442D96',
  iconColor: '#442D96',
  secondary: '#c1c1c1',
  success: '#442D96',
  activeOne: '#ffffff',
  textColor: '#ffffff',
  error: '#610006',
  titleColor: '#442d96',
  background: '#ffffff',
  menuBackground: '#442D96',
  menuWssBackground: '#2a1c4f',
  info: '#442D96',
  primaryLigth: '#ECE7F5',
  primaryMedium: '#442D96',
  primaryDark: '#2A1C4F',
  lowLight: '#ffffff',
  lowMedium: '#f7f7fd',
  lowDark: '#e7e7fa',
  highlightLight: '#e3FFee',
  highlightMedium: '#00ff99',
  highlightDark: '#00992b',
};

const other = {
  primary: '#9d9fa1',
  iconColor: '#9d9fa1',
  secondary: '#c1c1c1',
  success: '#8897AA',
  activeOne: '#f8f8f8',
  textColor: '#9d9fa1',
  error: '#8c2020',
  titleColor: '#4e5155',
  info: '#2f3337',
  background: '#f8f8f8',
  menuWssBackground: '#2f3337',
  menuBackground: '#2f3337',
  primaryLigth: '#ECE7F5',
  primaryMedium: '#2f3337',
  primaryDark: '#2f3337',
  lowLight: '#ffffff',
  lowMedium: '#f8f8f8',
  lowDark: '#f8f8f8',
  highlightLight: '#e3FFee',
  highlightMedium: '#00ff99',
  highlightDark: '#00992b',
};

export default new Vuetify({
  defaults: {
    global: {
      ripple: false,
    },
  },
  lang: {
    locales: { pt },
    current: 'pt',
  },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    light: true,
    themes: {
      light: isEmbeded != 'true' ? agnes : other,
    },
    options: {
      customProperties: true,
    },
  },
});
