import eSocialService from '@/services/eSocialService.js';
import interactionService from '@/services/interactionService.js';
import cloneDeep from 'lodash/cloneDeep';
import AdminServices from '@/services/adminServices.js';
import debounce from 'lodash/debounce';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';

export default {
  namespaced: true,

  state: {
    loading: false,
    pagination: {
      itemsPerPage: 50,
      page: 1,
      sortBy: [],
    },
    totCollaborators: 0,
    filter: {},
    scheduleReport: false,
    companies: [],
    collaborators: [],
    periods: [],
    maxExportLimit: 250,
  },
  getters: {
    getScheduleReport(state) {
      return state.scheduleReport;
    },
    getCompaniesList(state) {
      return state.companies;
    },
    getPagination(state) {
      return state.pagination;
    },
    getCollaborators(state) {
      return state.collaborators;
    },
    getTotCollaborators(state) {
      return state.totCollaborators;
    },
    getFilter(state) {
      return state.filter;
    },
    getLoadingTable(state) {
      return state.loading;
    },
  },
  actions: {
    async init({ dispatch, state }, userId) {
      if (userId != undefined) {
        dispatch('getCompanies', userId);
      } else {
        let { data } = await UserService.getUser();
        dispatch('getCompanies', data.id);
      }
      state.filter.nameCpfMatriculationRg = null;
      await dispatch('fetchCollaborators');
    },

    changeFilter({ commit, dispatch, state }, { prop, value }) {
      commit('SET_LOADING', true);
      const filter = cloneDeep(state.filter);
      filter[prop] = value;
      commit('SET_FILTER', filter);
      dispatch('fetchCollaborators');
    },

    setPagination({ commit, dispatch }, payload) {
      commit('SET_PAGINATION', payload);

      dispatch('fetchCollaboratorsDebounce');
    },

    async getCompanies({ commit }, payload) {
      let companies = [];

      let response = await AdminServices.userEstablishment.get(payload);
      let results = response.data;

      if (results && results.length > 0) {
        let establishments = [];
        results.forEach((item) => {
          establishments.push(item.establishment);
        });

        let groups = groupBy(establishments, 'socialName');

        groups = Object.entries(groups).map(([key, value]) => ({
          company: key,
          establishments: value,
        }));

        groups.forEach((item) => {
          let group = item.company;
          companies.push({ subtitle: group });
          item.establishments.forEach((innerItem) => {
            innerItem.group = group;

            companies.push(innerItem);
          });
        });
      }

      commit('SET_COMPANIES', companies);
    },

    fetchCollaboratorsDebounce: debounce(({ dispatch }) => {
      return dispatch('fetchCollaborators');
    }, 500),

    async fetchCollaborators({ commit, state }) {
      commit('SET_LOADING', true);

      try {
        const filter = {
          ...state.filter,
        };

        let pagination = `page=${state.pagination.page - 1}&size=${state.pagination.itemsPerPage}`;

        if (state.pagination.sortBy.length > 0) {
          pagination = `${pagination}&sort=${state.pagination.sortBy[0]},${
            state.pagination.sortDesc[0] === true ? 'desc' : state.pagination.sortDesc[0] === false ? 'asc' : ''
          }`;
        }
        let response = await eSocialService.collaborators.filterByDesc(filter, pagination);

        let collabs = response.data.content;

        commit('SET_LIST_COLLABORATORS', collabs);
        commit('SET_TOTAL_COLLABORATORS', response.data.totalElements);
      } catch (e) {
        commit('SET_LIST_COLLABORATORS', []);
        console.log(e);
      }

      commit('SET_LOADING', false);
    },
    // async exportCollaborators({ commit, state }) {
    //   let blob = new Blob();
    //   if (state.totCollaborators <= state.maxExportLimit) {
    //     let serialized = { params: state.filter };
    //     let pagination = `page=${state.pagination.page - 1}&size=${state.totCollaborators}`;
    //     if (state.pagination.sortBy.length > 0) {
    //       pagination = `${pagination}&sort=${state.pagination.sortBy[0]},${
    //         state.pagination.sortDesc[0] === true ? 'desc' : state.pagination.sortDesc[0] === false ? 'asc' : ''
    //       }`;
    //     }
    //     let response = await eSocialService.collaborators.export(serialized, pagination);

    //     blob = new Blob([response.data], {
    //       type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    //     });
    //   }

    //   if (state.totCollaborators > state.maxExportLimit) {
    //     let params = { module: "collaborators",size: state.totCollaborators, requestedAt: new Date() };
    //     console.log("Descomentar let response = await interactionService.document.download(params);");
    //     // let response = await interactionService.document.download(params);
    //   }

    //   commit('SET_BLOBCOLLABORATORS', blob);
    // },
    async exportCollaborators({ commit, state }) {
      let response = await eSocialService.collaborators.export();
      commit('SET_RESULT_SCHEDULE_REPORT', response.status === 202);
    },
  },

  mutations: {
    SET_COMPANIES(state, payload) {
      state.companies = payload;
    },
    SET_RESULT_SCHEDULE_REPORT(state, payload) {
      state.scheduleReport = payload;
    },

    SET_FILTER(state, payload) {
      state.filter = payload;
    },

    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },

    SET_LIST_COLLABORATORS(state, payload) {
      state.collaborators = payload;
    },

    SET_TOTAL_COLLABORATORS(state, payload) {
      state.totCollaborators = payload;
    },

    SET_LOADING(state, payload) {
      state.loading = payload;
    },
  },
};
