
import eSocialService from '@/services/eSocialService.js';
import AdminServices from '@/services/adminServices.js';
import UserService from '@/services/userService.js';
import { definePaginationQuery } from '@/utils/pagination';
import groupBy from 'lodash/groupBy';
import { cloneDeep } from 'lodash';

function maskCnpj(item) {
  let mascara = item.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

  return mascara;
}

function filtersDefaultState() {
  return {cnpj: [],search: null};
}
function getOnlyItemIdsArray(items) {
  let ids = [];
  items.forEach((item) => {
    if (![undefined, null].includes(item.itemId)) {
      ids.push(item.itemId);
    }
  });
  return ids;
}
function mapFiltersDataForRequest(filters) {
  let requestData = {};
  if (![undefined, null].includes(filters)) {
     
    if (![undefined, null].includes(filters.cnpj)) {
      if (filters.cnpj.length > 0) {
        requestData.cnpjs = getOnlyItemIdsArray(filters.cnpj);
      }
    }
    if (![undefined, null].includes(filters.search)) {
      requestData.tableIdCodeNameDescNature = `${filters.search}$nml$$lk$`;
    }
  }

  return requestData;
}
function paginationDefaultState() {
  return {
    itemsPerPage: 50,
    page: 1,
    sortBy: [],
  };
}

export default {
  namespaced: true,
  state: {
    userId: null,
    dataGraphs:{},
    loadingGraphs: true,
    scheduleReportStatus: false,
    filtersSaved: filtersDefaultState(),
    loadingFilters: true,
    analysisList: [],
    totalAnalysis: 0,
    selectCnpj: [],    
    pagination: paginationDefaultState(),
  },
  getters: {
    getFiltersSaved(state) {
      return state.filtersSaved
    },
    getDataGraphs(state) {
      return state.dataGraphs
    },
    getLoadingGraphs(state) {
      return state.loadingGraphs;
    },
    getLoadingFilters(state) {
      return state.loadingFilters
    },
    getAnalysisList(state) {
        return state.analysisList;
    },
    getTotalAnalysis(state) {
        return state.totalAnalysis;
    },
    getSelectCnpj(state) {
      return state.selectCnpj;
    },    
    getScheduleReportStatus(state) {
      return state.scheduleReportStatus;
    },
    getPagination(state) {
      return state.pagination;
    },
  },
  mutations: {    
    SET_USER_ID(state, payload) {
      state.userId = payload;
    },
    SET_DATA_GRAPHS(state, payload) {
      state.dataGraphs = payload;
    },
    SET_LOADING_GRAPHS(state, payload) {
      state.loadingGraphs = payload;
    },
    SET_FILTERS_SAVED(state, payload) {
      state.filtersSaved = payload;
    },
    SET_LOADING_FILTERS(state, payload) {
      state.loadingFilters = payload;
    },
    SET_ANALYSIS_LIST(state, payload) {
      state.analysisList = payload;
    },
    SET_TOTAL_ANALYSIS(state, payload) {
      state.totalAnalysis = payload;
    },
    SET_SELECT_CNPJ(state, payload) {
      state.selectCnpj = payload;
    },
    SET_RESULT_SCHEDULE_REPORT(state, payload) {
      state.scheduleReportStatus = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
  },
  actions: {
    init({ dispatch, commit }) {
      commit('SET_DATA_GRAPHS', {});
      commit('SET_LOADING_GRAPHS', true);
      commit('SET_FILTERS_SAVED', filtersDefaultState());
      commit('SET_ANALYSIS_LIST', []);
      commit('SET_TOTAL_ANALYSIS', 0); 
      commit('SET_SELECT_CNPJ', []);
      dispatch('fetchSelectCnpj');
    },
    setLoadingGraphs({ commit }, payload) {
      commit('SET_LOADING_GRAPHS', payload);
    },
    setPagination({ dispatch, commit }, payload) {
      dispatch('setLoadingFilters', true);
      commit('SET_PAGINATION', payload);
    },
    async fetchDataGraphs({ commit, dispatch, state }) {
      dispatch('setLoadingGraphs', true);
      let filters = cloneDeep(state.filtersSaved);
      let requestData = mapFiltersDataForRequest(filters);

      try {
        const data = (await eSocialService.rubricAnalysis.graphicGeneralVision(requestData)).data;
        data.byAnalysis.total = (data.byAnalysis.emAnalise + data.byAnalysis.disponivelParaUso);
        data.byAnalysis.emAnalisePercent = ((data.byAnalysis.emAnalise * 100)/data.byAnalysis.total);
        data.byAnalysis.disponivelParaUsoPercent = ((data.byAnalysis.disponivelParaUso * 100)/data.byAnalysis.total);
        
        commit('SET_DATA_GRAPHS', data);   
        if (data.byAnalysis.total > 0) {
          dispatch('setLoadingGraphs', false);
        }
      } catch (e) {
        console.error('AG_ERROR Error at fetchDataGraphs: ', e); 
        commit('SET_DATA_GRAPHS', {});
        dispatch('setLoadingGraphs', false);
      }
    },
    async fetchSearchAnalysis({ commit, dispatch, state }, payload) {
      dispatch('setLoadingFilters', true);
      let filters = cloneDeep(state.filtersSaved);
      filters.search = payload;
      commit('SET_FILTERS_SAVED', filters);
      dispatch('fetchAnalysis');
    },
    async fetchAnalysis({ commit, dispatch, state }) {
      dispatch('setLoadingFilters', true);
      let filters = cloneDeep(state.filtersSaved);
      commit('SET_FILTERS_SAVED', filters);
      let paginationObject = state.pagination;
      let pagination = definePaginationQuery(paginationObject);
      let requestData = mapFiltersDataForRequest(filters);
      try {                
          const response = await eSocialService.rubricAnalysis.analysis(requestData, pagination);
          response.data.content.forEach((item, index) => {
            item.id_column = index + 1;
          });
          commit('SET_ANALYSIS_LIST', response.data.content);
          let totalElements = response.data.totalElements;
          commit('SET_TOTAL_ANALYSIS', totalElements);          
          dispatch('fetchDataGraphs'); 
          dispatch('setLoadingFilters', false);
      } catch (error) {    
          console.error('AG_ERROR Error at fetchFilterSelects: ', error);
      }
    },
    applyFilters({ commit, dispatch }, payload) {
      commit('SET_FILTERS_SAVED', payload);    
      dispatch('fetchAnalysis'); 
    },
    clearFilters({ commit, dispatch }, payload) {
      commit('SET_FILTERS_SAVED', filtersDefaultState()); 
      dispatch('fetchDataGraphs',  filtersDefaultState()); 
      dispatch('fetchAnalysis',filtersDefaultState());
    },
    async fetchSelectCnpj({ state, dispatch, commit }) {
      if (![undefined, null].includes(state.userId)) {
        dispatch('getCompanies', state.userId);
      } else {
        try {
          let { data } = await UserService.getUser();
          let userId = data.id;

          commit('SET_USER_ID', userId);
          dispatch('getCompanies', userId);
        } catch (e) {
          console.error('AG_ERROR Error at fetchSelectCnpj: ', e);
        }
      }
    },
    async getCompanies({ commit, dispatch, getters}, payload) {
      try {
        let companies = [];
        let response = await AdminServices.userEstablishment.get(payload);
        if (response) {
          let results = response.data;

          if (results && results.length > 0) {
            let establishments = [];
            results.forEach((item) => {
              establishments.push(item.establishment);
            });
  
            let groups = groupBy(establishments, 'socialName');
  
            groups = Object.entries(groups).map(([key, value]) => ({
              company: key,
              establishments: value,
            }));
            var groupId = 1;
            groups.forEach((item) => {
              let group = item.company;
              let groupItems = [];
              item.establishments.forEach((innerItem) => {
                let newGroupItem = {
                  itemTitle: innerItem.socialName,
                  itemSubtitle: maskCnpj(innerItem.details.cnpj),
                  groupId: groupId,
                  itemId: innerItem.details.cnpj,
                };
                groupItems.push(newGroupItem);
              });
  
              companies.push({
                groupTitle: group,
                groupId: groupId,
                items: groupItems,
              });
              groupId++;
            });
          }
          commit('SET_SELECT_CNPJ', companies);
          dispatch('setLoadingFilters', false);

          let filterInitial = {cnpj: [], search: null};

          getters.getSelectCnpj.forEach(company => {   
            if (company.items) {
              company.items.forEach(element => {
                filterInitial.cnpj.push(element);
              });
            }         
          }); 
          
          commit('SET_FILTERS_SAVED',filterInitial); 
          dispatch('fetchAnalysis');
        }
      } catch (e) {
        console.error('AG_ERROR Error at getCompanies: ', e);
      }
    },
    async exportAutoAnalysis({ commit, state }) {
      let filters = cloneDeep(state.filtersSaved);

      let requestData = mapFiltersDataForRequest(filters);
      let response = await eSocialService.rubricAnalysis.export(requestData);
      commit('SET_RESULT_SCHEDULE_REPORT', response.status === 202);
    }, 
    setLoadingFilters({ commit }, payload) {
      commit('SET_LOADING_FILTERS', payload);
    },
  }
};