export const userRolesType = {
  ROLE_SYSTEM: 'Sistema',
  ROLE_ADMIN: 'Administrador',
  ROLE_MENU_SOURCE_DATA: 'Fonte de dados',
  ROLE_MENU_INTELLIGENCE: 'Indicadores',
  ROLE_MENU_ESOCIAL: 'eSocial',
  ROLE_MENU_ECAC: 'e-CAC / DCTF',
  ROLE_MENU_ECAC_MENSAGENS: 'e-CAC / Mensagens',
  ROLE_MENU_CRUD_MANAGER: 'Cadastros gerenciais',
  ROLE_MENU_CERTIDOES: 'Certidões',
  ROLE_ESOCIAL_APPROVER: 'Aprovador de rubricas',
  ROLE_MENU_PISCOFINS: 'PIS/COFINS',
  ROLE_MENU_TOTALIZADORES: 'Totalizadores',
  ROLE_MENU_DOWNLOADS: 'Documents',
  ROLE_CRF_ROBOT: 'Robô CRF',
  ROLE_CRF_MANAGER: 'Administrador CRF',
  ROLE_MENU_FILES_READ: 'Menu arquivos',
  ROLE_MENU_FILE_UPLOAD: 'Menu cadastro arquivos',
  ROLE_MENU_AFASTADOS_INATIVOS: 'Menu afastados e inativos',
  ROLE_MENU_CESSAO: 'Cedidos',
  ROLE_MENU_CBO: 'CBO',
};
