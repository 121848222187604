import documentsService from '@/services/interactionService.js';
import UserService from '@/services/userService.js';
import groupBy from 'lodash/groupBy';
import AdminServices from '@/services/adminServices.js';
// import downloadsMock from '@/assets/json/downloads';

export default {
  namespaced: true,

  state: {
    downloads: [],
    files: [],
    fileModalBool: false,
    fileModalItem: {},
    companies: [],
    paginationFiles: { itemsPerPage: 50, page: 1, sortBy: [] },
    paginationDownloads: { itemsPerPage: 50, page: 1, sortBy: [] },
    totalArquivos: 0,
    totalDownloads: 0,
    loadingTable: false,
    loadingDownloadTable: false,
    blobDownload: '',
  },
  getters: {
    getLoadingStatus(state) {
      return state.loadingTable;
    },
    getLoadingDownloadStatus(state) {
      return state.loadingDownloadTable;
    },
    getCompaniesList(state) {
      return state.companies;
    },
    getTotalArquivos(state) {
      return state.totalArquivos;
    },
    getTotalDownloads(state) {
      return state.totalDownloads;
    },
    getPaginationFiles(state) {
      return state.paginationFiles;
    },
    getPaginationDownloads(state) {
      return state.paginationDownloads;
    },
    getDownloads(state) {
      return state.downloads;
    },
    getFilesList(state) {
      return state.files;
    },
    getShowModal(state) {
      return state.fileModalBool;
    },
    getFileModalItem(state) {
      return state.fileModalItem;
    },
    getBlobDownload(state) {
      return state.blobDownload;
    },
  },
  actions: {
    async initFile({ dispatch, commit }, userId) {
      commit('SET_LOADINGFILESTABLE', true);
      commit('SET_LOADINGDOWNLOADTABLE', true);
      if (userId != undefined) {
        dispatch('getCompanies', userId);
      } else {
        let { data } = await UserService.getUser();
        dispatch('getCompanies', data.id);
      }

      // dispatch('getDownloads');
      // dispatch('getFiles');
    },
    async initDocument({ dispatch, commit }, userId) {
      commit('SET_LOADINGFILESTABLE', true);
      commit('SET_LOADINGDOWNLOADTABLE', true);
      if (userId != undefined) {
        dispatch('getCompanies', userId);
      } else {
        let { data } = await UserService.getUser();
        dispatch('getCompanies', data.id);
      }

      // dispatch('getDownloads');
      // dispatch('getFiles');
    },
    async getFiles({ commit }) {
      let { data } = await documentsService.files.get();

      commit('SET_TOTALFILES', data.length);
      commit('SET_FILES', data);
      commit('SET_LOADINGFILESTABLE', false);
    },
    async getDownloads({ commit }) {
      let report = 'report=true';
      let { data } = await documentsService.document.get(report);

      commit('SET_TOTALDOWNLOADS', data.length);
      commit('SET_DOWNLOADS', data);
      commit('SET_LOADINGDOWNLOADTABLE', false);
    },

    closeFileModal({ commit }, payload) {
      commit('SET_FILEMODALITEM', {});
      commit('SET_FILEMODALBOOL', payload);
    },
    openFileModal({ commit }, payload) {
      commit('SET_FILEMODALITEM', payload);
      commit('SET_FILEMODALBOOL', true);
    },
    async getCompanies({ commit }, payload) {
      let companies = [];

      let response = await AdminServices.userEstablishment.get(payload);
      let results = response.data;

      if (results && results.length > 0) {
        let establishments = [];
        results.forEach((item) => {
          establishments.push(item.establishment);
        });

        let groups = groupBy(establishments, 'socialName');

        groups = Object.entries(groups).map(([key, value]) => ({
          company: key,
          establishments: value,
        }));

        groups.forEach((item) => {
          let group = item.company;
          companies.push({ subtitle: group });
          item.establishments.forEach((innerItem) => {
            innerItem.group = group;

            companies.push(innerItem);
          });
        });
      }

      commit('SET_COMPANIES', companies);
    },

    setPaginationFiles({ dispatch, commit }, payload) {
      commit('SET_PAGINATIONFILES', payload);

      dispatch('getFiles');
    },

    setPaginationDownloads({ dispatch, commit }, payload) {
      commit('SET_PAGINATIONDOWNLOADS', payload);

      dispatch('getDownloads');
    },
    async exportDownload({ commit, state }, { item }) {
      let blob = new Blob();

      let serialized = { params: item };
      let response = await documentsService.downloads.post(serialized);

      blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      commit('SET_BLOBDOWNLOAD', blob);
    },
  },

  mutations: {
    SET_DOWNLOADS(state, payload) {
      state.downloads = payload;
    },
    SET_LOADINGFILESTABLE(state, payload) {
      state.loadingTable = payload;
    },
    SET_LOADINGDOWNLOADTABLE(state, payload) {
      state.loadingDownloadTable = payload;
    },
    SET_FILES(state, payload) {
      state.files = payload;
    },
    SET_TOTALFILES(state, payload) {
      state.totalArquivos = payload;
    },
    SET_TOTALDOWNLOADS(state, payload) {
      state.totalDownloads = payload;
    },
    SET_FILEMODALBOOL(state, payload) {
      state.fileModalBool = payload;
    },
    SET_FILEMODALITEM(state, payload) {
      state.fileModalItem = payload;
    },
    SET_COMPANIES(state, payload) {
      state.companiesList = payload;
    },
    SET_PAGINATIONFILES(state, payload) {
      state.paginationFiles = payload;
    },
    SET_PAGINATIONDOWNLOADS(state, payload) {
      state.paginationDownloads = payload;
    },
    SET_BLOBDOWNLOAD(state, payload) {
      state.blobDownload = payload;
    },
  },
};
