import { axiosAuth } from '@/services/config.js';
import router from '@/router/index.js';
import axios from 'axios';
// let testando = {"web-front-end": "bar-service-password"};

let fixedToken = {
  headers: {
    Authorization: 'Basic d2ViLWZyb250LWVuZDpiYXItc2VydmljZS1wYXNzd29yZA==',
  },
};

axiosAuth.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem('access_Token');
      localStorage.removeItem('refresh_Token');
      localStorage.setItem('Error', 'Token vencido, por favor fazer login novamente.');
      router.push('/login');
    }
    return Promise.reject(error);
  },
);

export default {
  get: (id) => {
    return axiosAuth.get('user/' + id);
  },

  find: () => {
    return axiosAuth.get('user?page=0&size=100');
  },

  login: (user) => {
    let passwordEncode = encodeURIComponent(user.password).replace(/\W/g, (c) => {
      return c.charAt(0);
    });
    return axiosAuth.post(
      `/oauth/token?username=${user.username}&password=${passwordEncode}&grant_type=password`,
      {},
      fixedToken,
    );
    // .catch((err) => {
    //   console.log('login error -> ' + err)
    //   throw err;
    // });
  },

  save: (data) => {
    return axiosAuth.post('/user', data);
  },

  update: (data) => {
    return axiosAuth.patch('/user/me', data);
  },

  updateOtherUser: (data) => {
    return axiosAuth.patch(`/user/${data.id}`, {
      authorities: data.authorities,
      firstName: data.firstName,
      lastName: data.lastName,
      emailAddress: data.emailAddress,
    });
  },
  updateOtherUserMenu: (id, data) => {
    return axiosAuth.patch('/user/' + id, data);
  },

  updatePassword: (data) => {
    return axiosAuth.patch('/user/me/change-password', data);
  },

  getUser: () => {
    return axiosAuth.get('/user/me/');
  },

  existsUsername: (value) => {
    return axiosAuth.get(`/user/exists/login?login=${value}`);
  },

  existsEmail: (value) => {
    return axiosAuth.get(`/user/exists/email?email=${value}`);
  },

  activates: (value) => {
    return axiosAuth.get(`/user/activates?email=${value}`);
  },

  qtyLoginAttempts: (value) => {
    return axiosAuth.get(`/user/attemps?username=${value}`);
  },

  enableTwofa: (uuid) => {
    if (uuid) {
      return axiosAuth.put(`/user/enable-2fa?uuid=${uuid}`);
    }

    return axiosAuth.put(`/user/enable-2fa`);
  },

  disableTwofa: (uuid) => {
    if (uuid) {
      return axiosAuth.put(`/user/disable-2fa?uuid=${uuid}`);
    }

    return axiosAuth.put(`/user/disable-2fa`);
  },

  loginTwofa: (accessCode) => {
    return axiosAuth.post(`/user/login-2fa/${accessCode}`);
  },

  checkTokenExpirationTime: (identificator) => {
    return axiosAuth.get(`/user/token/valid/${identificator}`);
  },

  role: {
    get: () => {
      return axiosAuth.get('role');
    },
  },
};
