import * as roles from '@/assets/json/menuRoles';
import validateRole from '@/middleware/validateRole';
import store from '@/store/';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const isEmbeded = process.env.VUE_APP_EMBEDED;

const routes = [
  {
    path: '/',
    name: 'Layout Auth',
    component: () =>
      isEmbeded != true
        ? import(/* webpackChunkName: "Auth" */ './layouts/Auth.vue')
        : import(/* webpackChunkName: "Vobys" */ './views/vobys/Sso.vue'),
    redirect: { name: isEmbeded != 'true' ? 'Login' : 'Vobys' },
    children: [
      {
        path: isEmbeded != 'true' ? '/login' : '/vobys-login',
        name: isEmbeded != 'true' ? 'Login' : 'Vobys',
        component: () =>
          isEmbeded != 'true'
            ? import(/* webpackChunkName: "Login" */ './views/auth/Login.vue')
            : import(/* webpackChunkName: "Vobys" */ './views/vobys/Sso.vue'),
        // meta: isEmbeded != 'true' ? {} : { roles: isAus.thFromVobys },
      },
      {
        path: '/token/tfa/confirm/:token?',
        name: 'ConfirmTokenTfa',
        props: true,
        component: () => import(/* webpackChunkName: "TFAconfirmscreen" */ './views/auth/components/ModalTfaToken.vue'),
      },
      {
        path: '/forgot/email',
        name: 'forgot-email',
        component: () =>
          import(/* webpackChunkName: "ResetEmail" */ './views/auth/components/ModalForgotPasswordEmail.vue'),
      },
      {
        path: '/forgot/email/confirm/:token',
        name: 'forgot-email-confirm',
        props: true,
        component: () =>
          import(/* webpackChunkName: "ResetEmailConfirm" */ './views/auth/components/ModalForgotPasswordConfirm.vue'),
      },
      {
        path: '/registration/first/access/:id',
        name: 'Tfa-First-Access',
        props: true,
        component: () => import(/* webpackChunkName: "TfaFirstAccess" */ './views/auth/components/TfaFirstAccess.vue'),
      },
      {
        path: '/forbidden',
        name: 'Forbidden not authorized',
        component: () => import('./views/vobys/Forbidden.vue'),
      },
    ],
  },
  {
    path: '/',
    name: 'Layout Main',
    component: () => import(/* webpackChunkName: "AppLayout" */ './layouts/Main.vue'),
    children: [
      {
        path: '/home',
        name: 'home-page',
        component: () => import(/* webpackChunkName: "Home"*/ './views/home/Home.vue'),
      },
      {
        path: '/war-smart-solutions',
        name: 'War Smart Solutions',
        component: () => import(/* webpackChunkName: "RouterLayout" */ './layouts/RouterLayout.vue'),
        meta: {
          roles: roles.esocial,
        },
        children: [
          {
            path: 'agentes-nocivos-e-aposentadoria-especial',
            name: 'agentes-nocivos-e-aposentadoria-especial-page',
            component: () =>
              import(
                /* webpackChunkName: "AgentesNocivosEAposentariaEspecial"*/ './views/wss/AgentesNocivosEAposentariaEspecial.vue'
              ),
            meta: {
              roles: roles.esocial,
            },
          },
          {
            path: 'cat',
            name: 'cat-page',
            component: () => import(/* webpackChunkName: "CAT"*/ './views/wss/CAT.vue'),
            meta: {
              roles: roles.esocial,
            },
          },
        ],
      },
      {
        path: '/cadastros-gerenciais',
        name: 'Layout Cadastros',
        component: () => import(/* webpackChunkName: "RouterLayout" */ './layouts/RouterLayout.vue'),
        children: [
          {
            path: 'index-process',
            name: 'Index-Process',
            component: () => import(/* webpackChunkName: "IndexProcess" */ './views/fonte-dados/IndexProcess.vue'),
            meta: {
              roles: roles.index,
            },
          },
          {
            path: 'upload-arquivo',
            name: 'Upload-Arquivo',
            component: () =>
              import(/* webpackChunkName: "UploadArquivoZip" */ './views/fonte-dados/UploadArquivoZip.vue'),
            meta: {
              roles: roles.admin,
            },
          },
          {
            path: 'ecac-process',
            name: 'Ecac-Process',
            component: () => import(/* webpackChunkName: "EcacProcess" */ './views/fonte-dados/EcacProcess.vue'),
            meta: {
              roles: roles.admin,
            },
          },
          {
            path: 'usuarios',
            name: 'Usuarios',

            component: () => import(/* webpackChunkName: "Usuarios" */ './views/cadastros-gerenciais/Usuarios.vue'),
            meta: {
              roles: roles.admin,
            },
          },
          {
            path: 'usuarios/:id',
            name: 'Usuario',
            props: true,
            redirect: { name: 'Usuario Dados' },
            component: () => import(/* webpackChunkName: "Usuario" */ './views/cadastros-gerenciais/Usuario.vue'),
            children: [
              {
                path: 'dados',
                name: 'Usuario Dados',
                props: true,

                component: () =>
                  import(
                    /* webpackChunkName: "UsuarioDados" */ './views/cadastros-gerenciais/usuario/UsuarioDados.vue'
                  ),
                meta: {
                  roles: roles.admin,
                },
              },
              {
                path: 'permissoes',
                name: 'Usuario Permissoes',
                props: true,

                component: () =>
                  import(
                    /* webpackChunkName: "UsuarioPermissoes" */ './views/cadastros-gerenciais/usuario/UsuarioPermissoes.vue'
                  ),
                meta: {
                  roles: roles.admin,
                },
              },
              {
                path: 'change-password',
                name: 'Segurança',
                props: true,

                component: () =>
                  import(
                    /* webpackChunkName: "UsuarioSeguranca" */ './views/cadastros-gerenciais/usuario/UsuarioSeguranca.vue'
                  ),
                meta: {
                  roles: roles.admin,
                },
              },
              {
                path: 'authentication',
                name: 'Autenticação',
                props: true,

                component: () =>
                  import(
                    /* webpackChunkName: "UsuarioAuthentication" */ './views/cadastros-gerenciais/usuario/UsuarioAuthentication.vue'
                  ),
                meta: {
                  middleware: roles.admin,
                },
              },
              {
                path: 'editorMenu',
                name: 'Editor menu',
                props: true,

                component: () =>
                  import(
                    /* webpackChunkName: "UsuarioEditorTelas" */ './views/cadastros-gerenciais/usuario/UsuarioEditorTelas.vue'
                  ),
                meta: {
                  roles: roles.admin,
                },
              },
              {
                path: 'estabelecimentos',
                name: 'Usuario Estabelecimentos',
                props: true,

                component: () =>
                  import(
                    /* webpackChunkName: "UsuarioEstabelecimentos" */ './views/cadastros-gerenciais/usuario/UsuarioEstabelecimentos.vue'
                  ),
                meta: {
                  roles: roles.admin,
                },
              },
            ],
          },
          {
            path: 'empresas',
            name: 'Empresas',

            component: () =>
              import(/* webpackChunkName: "EmpresasGerencial" */ './views/cadastros-gerenciais/Empresas.vue'),
            meta: {
              roles: roles.cadastros,
            },
          },
          {
            path: 'estabelecimentos',
            name: 'Estabelecimentos',

            component: () =>
              import(
                /* webpackChunkName: "EstabelecimentosGerencial" */ './views/cadastros-gerenciais/Estabelecimentos.vue'
              ),
            meta: {
              roles: roles.admin,
            },
          },
          {
            path: 'grupos',
            name: 'Grupos',

            component: () =>
              import(/* webpackChunkName: "GruposGerencial" */ './views/cadastros-gerenciais/Grupos.vue'),
            meta: {
              roles: roles.admin,
            },
          },
          {
            path: 'e-social/tables',
            name: 'Tables',

            component: () =>
              import(/* webpackChunkName: "TablesGerencial" */ './views/cadastros-gerenciais/e-social/Tables.vue'),
            meta: {
              roles: roles.admin,
            },
          },
          {
            path: 'e-social/tables/rubric-nature',
            name: 'RubricNature',

            component: () =>
              import(
                /* webpackChunkName: "RubricNatureGerencial" */ './views/cadastros-gerenciais/e-social/RubricNature.vue'
              ),
            meta: {
              roles: roles.admin,
            },
          },
          {
            path: 'e-social/tables/rubric-type',
            name: 'RubricType',

            component: () =>
              import(
                /* webpackChunkName: "RubricTypeGerencial" */ './views/cadastros-gerenciais/e-social/RubricType.vue'
              ),
            meta: {
              roles: roles.admin,
            },
          },
          {
            path: 'e-social/tables/incidence-inss',
            name: 'IncidenceINSS',

            component: () =>
              import(
                /* webpackChunkName: "IncidenceINSSGerencial" */ './views/cadastros-gerenciais/e-social/IncidenceINSS.vue'
              ),
            meta: {
              roles: roles.admin,
            },
          },
          {
            path: 'e-social/tables/incidence-irrf',
            name: 'IncidenceIRRF',

            component: () =>
              import(
                /* webpackChunkName: "IncidenceIRRFGerencial" */ './views/cadastros-gerenciais/e-social/IncidenceIRRF.vue'
              ),
            meta: {
              roles: roles.admin,
            },
          },
          {
            path: 'e-social/tables/incidence-fgts',
            name: 'IncidenceFGTS',

            component: () =>
              import(
                /* webpackChunkName: "IncidenceFGTSGerencial" */ './views/cadastros-gerenciais/e-social/IncidenceFGTS.vue'
              ),
            meta: {
              roles: roles.admin,
            },
          },
          {
            path: 'e-social/tables/incidence-cprp',
            name: 'IncidenceCPRP',

            component: () =>
              import(
                /* webpackChunkName: "IncidenceCPRPGerencial" */ './views/cadastros-gerenciais/e-social/IncidenceCPRP.vue'
              ),
            meta: {
              roles: roles.admin,
            },
          },
          {
            path: 'e-social/analysis-parameters',
            name: 'AnalysisParameters',

            component: () =>
              import(
                /* webpackChunkName: "AnalysisParametersGerencial" */ './views/cadastros-gerenciais/e-social/AnalysisParameters.vue'
              ),
            meta: {
              roles: roles.admin,
            },
          },

          {
            path: 'e-social/analysis-parameters/incidence-nature',
            name: 'IncidenceNature',

            component: () =>
              import(
                /* webpackChunkName: "IncidenceNatureGerencial" */ './views/cadastros-gerenciais/e-social/IncidenceNature.vue'
              ),

            meta: {
              roles: roles.admin,
            },
          },

          {
            path: 'e-social/analysis-parameters/cbo',
            name: 'CBO',

            component: () =>
              import(/* webpackChunkName: "CboGerencial" */ './views/cadastros-gerenciais/e-social/Cbo.vue'),

            meta: {
              roles: roles.admin,
            },
          },
          {
            path: 'e-social/analysis-parameters/cbo/cbo-form',
            name: 'CBOForm',

            component: () =>
              import(
                /* webpackChunkName: "CBOFormGerencial" */ './views/cadastros-gerenciais/e-social/components/CBOForm.vue'
              ),
            props: true,

            meta: {
              roles: roles.admin,
            },
          },
        ],
      },
      {
        path: '/fonte-dados/pis-cofins',
        name: 'Pis-Cofins-Upload',
        component: () => import(/* webpackChunkName: "PisCofinsUpload" */ './views/fonte-dados/PisCofinsUpload.vue'),
        meta: {
          roles: roles.pisCofins,
        },
      },
      {
        path: '/eSocial',
        name: 'Layout eSocial',
        component: () => import(/* webpackChunkName: "RouterLayout" */ './layouts/RouterLayout.vue'),
        children: [
          {
            path: 'indicadores',
            name: 'Indicadores-Dashboard',
            component: () =>
              import(/* webpackChunkName: "indicadores-Dashboard" */ './views/indicadores/Dashboard.vue'),
            meta: {
              roles: roles.esocial,
            },
          },
          {
            path: `/eSocial/trabalhador/cbo`,
            name: 'CBO',

            component: () => import(/* webpackChunkName: "Cbo" */ './views/eSocial/cbo/Cbo.vue'),
            meta: {
              roles: roles.cbo,
            },
          },
          {
            path: `/eSocial/trabalhador/cbo-search`,
            name: 'CBO-Search',

            component: () => import(/* webpackChunkName: "CboSearch" */ './views/eSocial/cbo/components/CboSearch.vue'),
            meta: {
              roles: roles.cbo,
            },
          },
          {
            path: `/eSocial/trabalhador/horarios-jornadas`,
            name: 'Horarios e Jornadas',
            component: () =>
              import(
                /* webpackChunkName: "HorariosJornadas" */ './views/eSocial/HorariosEJornadas/HorariosJornadas.vue'
              ),
            meta: {
              roles: roles.esocial,
            },
          },
          {
            path: `/eSocial/trabalhador/horarios-e-jornadas-history`,
            name: 'Historico de Horarios e Jornadas',
            component: () =>
              import(
                /* webpackChunkName: "HorariosJornadasHistory" */ './views/eSocial/HorariosEJornadas/components/HorariosJornadasHistory.vue'
              ),
            meta: {
              roles: roles.esocial,
            },
          },
          {
            path: 'trabalhador',
            name: 'Layout Colaboradores',
            component: () => import(/* webpackChunkName: "RouterLayout" */ './layouts/RouterLayout.vue'),
            children: [
              {
                path: '/eSocial/trabalhador/:todos',
                name: 'Todos',
                component: () => import(/* webpackChunkName: "Trabalhador" */ './views/eSocial/Colaboradores.vue'),
                meta: {
                  roles: roles.esocial,
                },
              },
              {
                path: '/eSocial/trabalhador/:afastados-e-inativos',
                name: 'Afastados-e-inativos',

                component: () => import(/* webpackChunkName: "Trabalhador" */ './views/eSocial/Colaboradores.vue'),
                meta: {
                  roles: roles.afastadosInativos,
                },
              },
              {
                path: '/eSocial/trabalhador/:cedidos',
                name: 'Cedidos',

                component: () => import(/* webpackChunkName: "Trabalhador" */ './views/eSocial/Colaboradores.vue'),
                meta: {
                  roles: roles.cessao,
                },
              },
            ],
          },

          {
            path: 'empresas',
            name: 'Empresas-esocial',

            component: () => import(/* webpackChunkName: "EmpresasEsocial" */ './views/eSocial/Empresas.vue'),
            meta: {
              roles: roles.esocial,
            },
          },
          {
            path: 'estabelecimentos',
            name: 'Estabelecimentos-esocial',

            component: () =>
              import(/* webpackChunkName: "EstabelecimentosEsocial" */ './views/eSocial/Estabelecimentos.vue'),
            meta: {
              roles: roles.esocial,
            },
          },
          {
            path: 'relatorios',
            name: 'Relatorios-esocial',

            component: () => import(/* webpackChunkName: "RelatoriosEsocial" */ './views/eSocial/Relatorios.vue'),
            meta: {
              roles: roles.esocial,
            },
          },

          {
            path: 'rubricas',
            name: 'Rubricas',
            component: () => import(/* webpackChunkName: "Rubricas" */ './views/eSocial/Rubricas.vue'),
            meta: {
              roles: roles.esocial,
            },
          },
          {
            path: 'rubricas/:id',
            name: 'Rubrica',
            props: true,

            component: () => import(/* webpackChunkName: "Rubrica" */ './views/eSocial/Rubrica.vue'),
            meta: {
              roles: roles.esocial,
            },
          },
          {
            path: 'rubricas-new',
            name: 'Rubricas Novo',
            component: () => import(/* webpackChunkName: "Rubricas" */ './views/eSocial/RubricasNew.vue'),
            meta: {
              roles: roles.esocial,
            },
          },
        ],
      },

      {
        path: '/ecac/dctf',
        name: 'ECAC',

        component: () => import(/* webpackChunkName: "ECACDctf" */ './views/ecac/Dctf.vue'),
        meta: {
          roles: roles.ecac,
        },
      },
      {
        path: '/ecac/mensagens',
        name: 'Mensagens',
        props: true,

        component: () => import(/* webpackChunkName: "ECACMensagens" */ './views/ecac/Mensagens.vue'),
        meta: {
          roles: roles.ecacMensagens,
        },
      },
      {
        path: '/piscofins/relatorios',
        name: 'RelatoriosPisCofins',

        component: () => import(/* webpackChunkName: "RelatoriosPisCofins" */ './views/pis-cofins/Relatorios.vue'),
        meta: {
          roles: roles.pisCofins,
        },
      },

      {
        path: '/ativacao',
        name: 'Cadastro Usuario Ativacao',
        props: true,

        component: () =>
          import(
            /* webpackChunkName: "CadastroUsuarioAtivacao" */ './views/cadastros-gerenciais/usuario/UsuarioCadastradoAtivacao.vue'
          ),
        meta: {
          roles: roles.admin,
        },
      },
      {
        path: '/help-desk/chamados',
        name: 'Chamados',
        component: () => import(/* webpackChunkName: "Chamados" */ './views/help-desk/Chamados.vue'),
      },

      {
        path: '/profile',
        name: 'Layout Profile',
        component: () => import('./layouts/UserProfile.vue'),
        redirect: { name: 'Perfil edit' },
        children: [
          {
            path: 'edit',
            name: 'Perfil edit',
            component: () => import('./views/usuario/Perfil.vue'),
          },
          {
            path: 'change-password',
            name: 'Password change',
            component: () => import('./views/usuario/ChangePassword.vue'),
          },
          {
            path: 'authentication',
            name: 'Autenticação (2FA)',
            component: () => import('./views/usuario/Authentication2FA.vue'),
          },
        ],
      },

      {
        path: '/certidoes',
        name: 'Certidoes',

        component: () => import('./views/certidoes/Certidoes.vue'),
        meta: {
          roles: roles.certidoes,
        },
      },
      {
        path: '/certidoes/historico/:idCertidao',
        name: 'Histórico Certidao',
        props: true,

        component: () => import('./views/certidoes/CertidaoHistorico.vue'),
        meta: {
          roles: roles.certidoes,
        },
      },
      {
        path: '/crf',
        name: 'Crf',

        component: () => import('./views/certidoes/Crf.vue'),
        meta: {
          roles: roles.crf,
        },
      },
      {
        path: '/crf/historico/:idCrf',
        name: 'Histórico Crf',
        props: true,

        component: () => import('./views/certidoes/CrfHistorico.vue'),
        meta: {
          roles: roles.crf,
        },
      },
      {
        path: '/totalizadores',
        name: 'Totalizadores',

        component: () => import('./views/totalizador/totalizadores.vue'),
        meta: {
          roles: roles.totalizadores,
        },
      },
      {
        path: '/documents/download',
        name: 'Download',

        component: () => import('./views/documents/download/Download.vue'),
        meta: {
          roles: roles.downloads,
        },
      },
      {
        path: '/documents/file',
        name: 'File',

        component: () => import('./views/documents/file/File.vue'),
        meta: {
          roles: roles.filesRead,
        },
      },
      {
        path: '/cadastros-gerenciais/configuracoes-ecac',
        name: 'ConfiguracoesEcac',

        component: () => import('./views/cadastros-gerenciais/ConfiguracoesEcac.vue'),
        meta: {
          roles: roles.admin,
        },
      },
      {
        path: '/cadastros-gerenciais/auditoria',
        name: 'Auditoria',

        component: () => import('./views/cadastros-gerenciais/Auditoria.vue'),
        meta: {
          roles: roles.admin,
        },
      },
      {
        path: '/cadastros-gerenciais/cadastro-de-documentos',
        name: 'cadastros documentos',

        component: () => import('./views/documents/file/FileUpload.vue'),
        meta: {
          roles: roles.filesUpload,
        },
      },
    ],
  },
];

// const routes = isEmbed === 'true' ? routesForEmbed : routesForAll;

const router = new VueRouter({
  mode: 'history',
  base: '/front-end/',
  routes,
});

// Handle navigation duplication for router push (Globally)
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  // eslint-disable-next-line
  return originalPush.call(this, location).catch((error) => {
    throw error;
  });
};

router.beforeEach((to, from, next) => {
  if (to.meta.roles) {
    const context = {
      from,
      next,
      router,
      to,
    };
    let payload = {
      to: to.path,
      from: from.path,
    };
    store.dispatch('routerModule/setRoute', payload);

    return validateRole(to.meta.roles, context);
  }

  return next();
});

export default router;
