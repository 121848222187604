import { axiosInteraction } from '@/services/config.js';
import router from '@/router/index.js';

let config = { headers: { 'Content-Type': 'multipart/form-data' } };
let configforXML = {
  responseType: 'arraybuffer',
  contentType: 'application/force-download',
  'Content-Disposition': 'attachment',
};
axiosInteraction.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    
    if (error.response.status === 401) {
      localStorage.removeItem('access_Token');
      localStorage.removeItem('refresh_Token');
      localStorage.setItem('Error', 'Token vencido, por favor fazer login novamente.');
      router.push('/');
    }
    return Promise.reject(error);
  },
);

export default {
  visitor: {
    getToken: (email) => {
      return axiosInteraction.get('visitor/token/' + email);
    },
  },
  ticket: {
    findByUserMail: (email) => {
      return axiosInteraction.get('ticket/find/' + email);
    },
  },
  document: {
    download: (params) => {
      return axiosInteraction.post('document/schedule-export/', params);
    },
    get: (params) => {
      return axiosInteraction.get(`document?` + params);
    },
  },
  files: {
    get: () => {
      return axiosInteraction.get(`document`);
    },
    upload: (formData) => {
      return axiosInteraction.post(`document`, formData, config);
    },
  },
};
