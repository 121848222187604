import eSocialService from '@/services/eSocialService.js';
import AdminServices from '@/services/adminServices.js';
import UserService from '@/services/userService.js';
import groupBy from 'lodash/groupBy';
import cloneDeep from 'lodash/cloneDeep';
import { definePaginationQuery } from '@/utils/pagination';

function filtersDefaultState() {
  return {
    originCnpjList: [],
    destinationCnpjList: [],
    tpRegPrevList: [],
    periodo: [],
    originRemunDeclarationToggle: null,
    isCeded: false,
    isNotCeded: false,
    isCededOnCurrentDate: false,
    filterTerm: null,
  };
}
function getOnlyItemIdsArray(items) {
  let ids = [];
  items.forEach((item) => {
    if (![undefined, null].includes(item.itemId)) {
      ids.push(item.itemId);
    }
  });
  return ids;
}
function mapUniquesValuesFromArray(itemList) {
  let newList = Array.from(new Map(itemList.map((item) => [item.itemId, item])).values());
  return newList;
}
function mapFiltersDataForRequest(filters) {
  let requestData = {};

  if (![undefined, null].includes(filters)) {
    requestData.originRemunDeclarationToggle = filters.originRemunDeclarationToggle;
    requestData.startDate = formatDateIni(filters.periodo[0]);
    requestData.endDate = formatDateFim(filters.periodo[1]);
    requestData.originCnpjList = getOnlyItemIdsArray(filters.originCnpjList);
    requestData.destinationCnpjList = getOnlyItemIdsArray(filters.destinationCnpjList);
    requestData.isCeded = filters.isCeded;
    requestData.isCededOnCurrentDate = filters.isCededOnCurrentDate;
    if (![undefined, null].includes(filters.filterTerm)) {
      requestData.filterTerm = filters.filterTerm + '$lk$$nml$';
    }
  }

  return requestData;
}
function formatDateIni(today) {
  if (![undefined, null, NaN].includes(today)) {
    const [year, month] = today.split('-');
    return `${year}-${month}-01`;
  }
}
function formatDateFim(today) {
  if (![undefined, null, NaN].includes(today)) {
    const [y, m] = today.split('-');
    let newDay = new Date();
    newDay.setFullYear(y, m, 0);
    let month = newDay.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }

    let formated = newDay.getFullYear() + '-' + month + '-' + newDay.getDate();

    return formated;
  }
}

function getPercentual(x, total) {
  let percentual = 0;
  if (![undefined, null, NaN, 0].includes(total)) {
    percentual = ((x * 100) / total).toFixed(2);
  }
  return Number(percentual);
}

function isEmpty(payload) {
  if (![undefined, null, 0].includes(payload)) {
    return Number(payload);
  } else {
    return Number(0);
  }
}
function defaultCardGraphs() {
  let types = [
    {
      title: 'Total de cessão/Exercício em Outro Órgão',
      value: [0, 100],
      percentual: '0%',
      description: ' em relação ao total de servidores',
      color: ['#442d96', '#E7E7FA'],
      name: 'totCessao',
      status: true,
    },
    {
      title: 'Cedidos com declaração de remuneração no órgão de origem',
      value: [0, 100],
      percentual: '0%',
      description: ' em relação ao total de cedidos',
      color: ['#442d96', '#E7E7FA'],
      name: 'cedidoComDeclaracao',
      status: false,
    },
    {
      title: 'Cedidos sem declaração de remuneração no órgão de origem',
      value: [0, 100],
      percentual: '0%',
      description: ' em relação ao total de cedidos',
      color: ['#C73C44', '#E7E7FA'],
      name: 'cedidoSemDeclaracao',
      status: false,
    },
  ];

  return types;
}

export default {
  namespaced: true,
  state: {
    loadingGraphs: true,
    loadingFilters: true,
    colaboradoresList: [],
    scheduleReportStatus: false,
    totalColaboradores: 0,
    totalColaboradoresCurrent: 0,
    filtersSaved: filtersDefaultState(),
    loading: false,
    countIsNotCeded: 0,
    countCeded: 0,
    isTotDisabled: false,
    isCardOneDisabled: false,
    loadingColaboradoresList: true,
    currentDisabled: false,
    isCardTwoDisabled: false,
    isCardThreeDisabled: false,
    graphData: {},
    cardGraphData: defaultCardGraphs(),
    selectCnpj: [],
    fetchCurrent: false,
    selectRegPrev: [],
    selectDestinationCnpjList: [],
    selectOriginCnpjList: [],
    pagination: {
      itemsPerPage: 50,
      page: 1,
      sortBy: [],
    },
  },

  getters: {
    getTotCollaboratorsCurrent(state) {
      return state.totalColaboradoresCurrent;
    },
    getCountIsNotCeded(state) {
      return state.countIsNotCeded;
    },
    getCountCeded(state) {
      return state.countCeded;
    },
    getTotCollaborators(state) {
      return state.totalColaboradores;
    },
    getCurrentDisabled(state) {
      return state.currentDisabled;
    },
    getLoadingColaboradoresList(state) {
      return state.loadingColaboradoresList;
    },
    getPagination(state) {
      return state.pagination;
    },
    getSelectCnpj(state) {
      return state.selectCnpj;
    },
    getSelectDestinationCnpjList(state) {
      return state.selectDestinationCnpjList;
    },
    getSelectOriginCnpjList(state) {
      return state.selectOriginCnpjList;
    },
    getSelectRegPrev(state) {
      return state.selectRegPrev;
    },
    getFiltersSaved(state) {
      return state.filtersSaved;
    },
    getColaboradoresList(state) {
      return state.colaboradoresList;
    },
    getLoadingGraphs(state) {
      return state.loadingGraphs;
    },
    getLoadingFilters(state) {
      return state.loadingFilters;
    },
    getCardOneDisabled(state) {
      return state.isCardOneDisabled;
    },
    getCardTwoDisabled(state) {
      return state.isCardTwoDisabled;
    },
    getCardThreeDisabled(state) {
      return state.isCardThreeDisabled;
    },
    getDataForGraph(state) {
      return state.graphData;
    },
    getCardGraphData(state) {
      return state.cardGraphData;
    },
    getScheduleReportStatus(state) {
      return state.scheduleReportStatus;
    },
    getDefaultFilterState() {
      return filtersDefaultState();
    },
  },

  actions: {
    init({ dispatch, commit }, payload) {
      commit('SET_BARGRAPH_DATA', {});
      commit('SET_CARDGRAPH_DATA', defaultCardGraphs());
      commit('SET_COLABORADORES_LIST', []);
      commit('SET_FILTERS_SAVED', filtersDefaultState());
      commit('SET_FETCH_CURRENT', true);
      dispatch('getFilters');
    },
    setPagination({ dispatch, commit }, payload) {
      dispatch('setLoadingColaboradoresList', true);
      commit('SET_PAGINATION', payload);
      dispatch('fetchTableData');
    },
    async getFilters({ commit, dispatch }) {
      let responseFilter = (await eSocialService.cedidos.getFilters()).data;
      let uniqueOriginCnpjsList = mapUniquesValuesFromArray(responseFilter.groupOriginCnpjList);
      let uniqueDestinationCnpjsList = mapUniquesValuesFromArray(responseFilter.groupDestinationCnpjList);

      commit('SET_SELECT_REGPREV', responseFilter.groupRegimesPrevidenciarios);
      commit('SET_SELECT_ORIGINCNPJLIST', uniqueOriginCnpjsList);
      commit('SET_SELECT_DESTINATIONCNPJLIST', uniqueDestinationCnpjsList);

      commit('SET_FILTER_LOADING', false);
      let isInitial = true;
      dispatch('getDashboard', isInitial);
    },
    async getDashboard({ commit, dispatch, state }, payload) {
      if (payload != true) {
        commit('SET_FILTERS_SAVED', payload);
      } else {
        commit('SET_FILTERS_SAVED', filtersDefaultState());
      }
      let filters = cloneDeep(state.filtersSaved);

      if (filters.isCeded === true) {
        delete filters.isNotCeded;
      } else if (filters.isNotCeded === true) {
        filters.isCeded = false;
        delete filters.isNotCeded;
      } else if (filters.isCeded === false && filters.isNotCeded === false) {
        filters.isCeded = null;
        delete filters.isNotCeded;
      }

      let requestData = mapFiltersDataForRequest(filters);
      let responseDash = (await eSocialService.cedidos.getDashboard(requestData)).data;
      if (![undefined, null].includes(responseDash)) {
        if (![undefined, null].includes(responseDash.cessionRemunerationByPeriod)) {
          let proportionRemunerationByPeriod = responseDash.cessionRemunerationByPeriod[0];
          dispatch('barGraph', proportionRemunerationByPeriod);
        } else {
          dispatch('barGraph', { data: [], series: [] });
        }
        if (![undefined, null].includes(responseDash.cessionProportionByRemuneration)) {
          let proportionRemunerationByPeriodResult = responseDash.cessionProportionByRemuneration;
          dispatch('cardGraphs', proportionRemunerationByPeriodResult);
        } else {
          commit('SET_CARDGRAPH_DATA', defaultCardGraphs());
          commit('SET_GRAPHLOADING', false);
        }
        if (payload != true) {
          dispatch('fetchTableData');
        }
      } else {
        commit('SET_GRAPHLOADING', false);
        dispatch('setLoadingColaboradoresList', false);
      }
    },
    async barGraph({ commit }, payload) {
      commit('SET_BARGRAPH_DATA', payload);
      commit('SET_GRAPHLOADING', false);
    },

    async cardGraphs({ commit, state }, payload) {
      let withDeclaration = isEmpty(payload.remuneracaoCedenteCount);
      let withoutDeclaration = isEmpty(payload.remuneracaoCessionarioCount);
      let total = isEmpty(withDeclaration) + isEmpty(withoutDeclaration);

      let percentualTotal = getPercentual(total, total);
      let percentualWithDeclaration = getPercentual(withDeclaration, total);
      let percentualWithoutDeclaration = getPercentual(withoutDeclaration, total);

      let types = [
        {
          title: 'Total de cessão/Exercício em Outro Órgão',
          value: percentualTotal === 0 ? [0, 1] : [percentualTotal, 0],
          valueTot: total,
          percentual: percentualTotal + '%',
          description: ' em relação ao total de servidores',
          color: ['#442d96', '#E7E7FA'],
          name: 'totCessao',
          status: cloneDeep(state.isCardOneDisabled),
        },
        {
          title: 'Cedidos <strong>com declaração</strong> de remuneração no órgão de origem',
          value:
            percentualWithDeclaration === 0 && percentualWithoutDeclaration === 0
              ? [0, 1]
              : [percentualWithDeclaration, percentualWithoutDeclaration],
          valueTot: withDeclaration,
          percentual: percentualWithDeclaration + '%',
          description: ' em relação ao total de cedidos',
          color: ['#442d96', '#E7E7FA'],
          name: 'cedidoComDeclaracao',
          status: cloneDeep(state.isCardTwoDisabled),
        },
        {
          title: 'Cedidos <strong>sem declaração</strong> de remuneração no órgão de origem',
          value:
            percentualWithDeclaration === 0 && percentualWithoutDeclaration === 0
              ? [0, 1]
              : [percentualWithoutDeclaration, percentualWithDeclaration],
          valueTot: withoutDeclaration,
          percentual: percentualWithoutDeclaration + '%',
          description: ' em relação ao total de cedidos',
          color: ['#C73C44', '#E7E7FA'],
          name: 'cedidoSemDeclaracao',
          status: cloneDeep(state.isCardThreeDisabled),
        },
      ];

      commit('SET_CARDGRAPH_DATA', types);
      commit('SET_GRAPHLOADING', false);
    },
    updateCardGraph({ state, commit }) {
      let graphs = cloneDeep(state.cardGraphData);
      graphs[0].status = cloneDeep(state.isCardOneDisabled);
      graphs[1].status = cloneDeep(state.isCardTwoDisabled);
      graphs[2].status = cloneDeep(state.isCardThreeDisabled);

      commit('SET_CARDGRAPH_DATA', graphs);
    },
    applyFilters({ commit, dispatch }, payload) {
      commit('SET_FILTERS_SAVED', payload);

      dispatch('getDashboard', payload);
    },
    checkFilterForCard({ dispatch, commit }, payload) {
      let status = [];
      if (payload === true) {
        status = [false, true, false];
      } else if (payload === false) {
        status = [false, false, true];
      } else if (payload === null) {
        status = [true, false, false];
      }
      dispatch('changeGraphBool', status);
      dispatch('updateCardGraph');
    },
    fetchCedidosCurrentDate({ state, dispatch, commit }, payload) {
      commit('SET_GRAPHLOADING', true);
      commit('SET_FETCH_CURRENT', true);

      dispatch('setLoadingColaboradoresList', true);

      let localFilter = cloneDeep(state.filtersSaved);

      if (payload === true) {
        localFilter.isCededOnCurrentDate = payload;
      } else if (payload === false) {
        localFilter.isCededOnCurrentDate = false;
      }

      dispatch('applyFilters', localFilter);
    },

    clearFilters({ dispatch, commit, state }) {
      commit('SET_FILTERS_SAVED', filtersDefaultState());
      let filters = cloneDeep(state.filtersSaved);
      let status = [true, false, false];
      dispatch('changeGraphBool', status);
      dispatch('updateCardGraph');

      dispatch('getDashboard', filters);
    },

    filterFromTable({ commit, state, dispatch }, payload) {
      dispatch('setLoadingColaboradoresList', true);
      let filters = cloneDeep(state.filtersSaved);
      filters.filterTerm = payload;
      if (filters.isCeded === true) {
        delete filters.isNotCeded;
      } else if (filters.isNotCeded === true) {
        filters.isCeded = false;
        delete filters.isNotCeded;
      } else if (filters.isCeded === false && filters.isNotCeded === false) {
        filters.isCeded = null;
        delete filters.isNotCeded;
      }
      commit('SET_FILTERS_SAVED', filters);
      dispatch('fetchTableData');
    },
    countForToggles({ commit }, payload) {
      let isNotCeded = 0;
      let isCeded = 0;

      if (payload) {
        isNotCeded = payload.reduce((acc, cur) => (cur.situacao != 'ceded' ? ++acc : acc), 0);
        isCeded = payload.reduce((acc, cur) => (cur.situacao === 'ceded' ? ++acc : acc), 0);
        commit('SET_COUNT_IS_NOT_CEDED', isNotCeded);
        commit('SET_COUNT_CEDED', isCeded);
      } else return;
    },
    countTotCedido({ commit }, payload) {
      let isCeded = 0;
      if (payload) {
        isCeded = payload.reduce((acc, cur) => (cur.situacao === 'ceded' ? ++acc : acc), 0);
        commit('SET_TOTAL_COLABORADORES_CURRENT', isCeded);
      } else return;
    },
    async fetchTableData({ commit, dispatch, state }) {
      dispatch('setLoadingColaboradoresList', true);
      let filters = cloneDeep(state.filtersSaved);

      if (filters.isCeded === true) {
        delete filters.isNotCeded;
      } else if (filters.isNotCeded === true) {
        filters.isCeded = false;
        delete filters.isNotCeded;
      } else if (filters.isCeded === false && filters.isNotCeded === false) {
        filters.isCeded = null;
        delete filters.isNotCeded;
      }
      let paginationObject = state.pagination;
      let pagination = definePaginationQuery(paginationObject);
      let requestData = mapFiltersDataForRequest(filters);
      try {
        const { data } = await eSocialService.cedidos.getTable(requestData, pagination);

        data.content.forEach((item, index) => {
          item.id_column = index + 1;
        });

        if (![undefined, null].includes(data.content)) {
          dispatch('checkFilterForCard', filters.originRemunDeclarationToggle);
        } else {
          let status = [true, false, false];
          dispatch('changeGraphBool', status);
          dispatch('updateCardGraph');
        }

        commit('SET_COLABORADORES_LIST', data.content);
        dispatch('countForToggles', data.content);
        let totalElements = data.totalElements;

        // if (state.fetchCurrent === true) {
        //   dispatch('countTotCedido', data.content);
        //   commit('SET_FETCH_CURRENT', false);
        // }

        commit('SET_TOTAL_COLABORADORES', totalElements);

        dispatch('setLoadingColaboradoresList', false);
      } catch (e) {
        commit('SET_COLABORADORES_LIST', []);
        commit('SET_TOTAL_COLABORADORES', 0);
        dispatch('setLoadingColaboradoresList', false);

        console.error('Error at get collaborators data:', e);
      }
    },

    async exportCollaborators({ commit, dispatch, state }, payload) {
      let filters = cloneDeep(state.filtersSaved);
      if (filters.isCeded === true) {
        delete filters.isNotCeded;
      } else if (filters.isNotCeded === true) {
        filters.isCeded = false;
        delete filters.isNotCeded;
      } else if (filters.isCeded === false && filters.isNotCeded === false) {
        filters.isCeded = null;
        delete filters.isNotCeded;
      }

      let requestData = mapFiltersDataForRequest(filters);
      let responseDash = await eSocialService.cedidos.export(requestData);
      commit('SET_RESULT_SCHEDULE_REPORT', responseDash.status === 202);
    },
    fetchFromCard({ dispatch }, payload) {
      dispatch('setLoadingColaboradoresList', true);
      if (payload === 'totCessao') {
        dispatch('fetchFromCardTot');
      } else if (payload === 'cedidoComDeclaracao') {
        dispatch('fetchFromCardCedidoComDeclaracao');
      } else if (payload === 'cedidoSemDeclaracao') {
        dispatch('fetchFromCardCedidoSemDeclaracao');
      }
    },
    fetchFromCardTot({ dispatch, state }, payload) {
      let status = [true, false, false];

      if (!state.isCardOneDisabled) {
        dispatch('changeGraphBool', status);
      } else {
        status[0] = false;
        dispatch('changeGraphBool', status);
      }

      dispatch('fetchFromCardWithParam', null);
    },
    fetchFromCardCedidoComDeclaracao({ commit, dispatch, state }, payload) {
      let status = [false, true, false];

      if (!state.isCardTwoDisabled) {
        dispatch('changeGraphBool', status);
        dispatch('fetchFromCardWithParam', true);
      } else {
        status[1] = false;
        dispatch('changeGraphBool', status);
        dispatch('fetchFromCardWithParam', null);
      }
    },
    fetchFromCardCedidoSemDeclaracao({ commit, dispatch, state }, payload) {
      let status = [false, false, true];

      if (!state.isCardThreeDisabled) {
        dispatch('changeGraphBool', status);
        dispatch('fetchFromCardWithParam', false);
      } else {
        status[2] = false;
        dispatch('changeGraphBool', status);
        dispatch('fetchFromCardWithParam', null);
      }
    },

    changeGraphBool({ commit }, payload) {
      commit('SET_CHARTONE_STATUS', payload[0]);
      commit('SET_CHARTTWO_STATUS', payload[1]);
      commit('SET_CHARTTHREE_STATUS', payload[2]);
    },
    setLoadingColaboradoresList({ commit }, payload) {
      commit('SET_LOADING_COLABORADORES_LIST', payload);
    },

    fetchFromCardWithParam({ commit, state, dispatch }, payload) {
      let localFilter = cloneDeep(state.filtersSaved);

      localFilter.originRemunDeclarationToggle = payload;

      commit('SET_FILTERS_SAVED', localFilter);
      dispatch('updateCardGraph');
      dispatch('fetchTableData');
    },
  },

  mutations: {
    SET_CARDGRAPH_DATA(state, payload) {
      state.cardGraphData = payload;
    },
    SET_BARGRAPH_DATA(state, payload) {
      state.graphData = payload;
    },
    SET_RESULT_SCHEDULE_REPORT(state, payload) {
      state.scheduleReportStatus = payload;
    },
    SET_COLABORADORES_LIST(state, payload) {
      state.colaboradoresList = payload;
    },
    SET_TOTAL_COLABORADORES(state, payload) {
      state.totalColaboradores = payload;
    },
    SET_TOTAL_COLABORADORES_CURRENT(state, payload) {
      state.totalColaboradoresCurrent = payload;
    },
    SET_FILTERS_SAVED(state, payload) {
      state.filtersSaved = payload;
    },
    SET_FETCH_CURRENT(state, payload) {
      state.fetchCurrent = payload;
    },
    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },
    SET_COUNT_IS_NOT_CEDED(state, payload) {
      state.countIsNotCeded = payload;
    },
    SET_COUNT_CEDED(state, payload) {
      state.countCeded = payload;
    },
    SET_GRAPHLOADING(state, payload) {
      state.loadingGraphs = payload;
    },
    SET_FILTER_LOADING(state, payload) {
      state.loadingFilters = payload;
    },
    SET_CHARTONE_STATUS(state, payload) {
      state.isCardOneDisabled = payload;
    },
    SET_CHARTTWO_STATUS(state, payload) {
      state.isCardTwoDisabled = payload;
    },
    SET_CHARTTHREE_STATUS(state, payload) {
      state.isCardThreeDisabled = payload;
    },
    SET_SELECT_CNPJ(state, payload) {
      state.selectCnpj = payload;
    },
    SET_SELECT_REGPREV(state, payload) {
      state.selectRegPrev = payload;
    },
    SET_SELECT_ORIGINCNPJLIST(state, payload) {
      state.selectOriginCnpjList = payload;
    },
    SET_SELECT_DESTINATIONCNPJLIST(state, payload) {
      state.selectDestinationCnpjList = payload;
    },
    SET_LOADING_CNPJ_FILTER(state, payload) {
      state.loadingCnpjFilter = payload;
    },
    SET_CURRENT_DISABLED(state, payload) {
      state.currentDisabled = payload;
    },
    SET_LOADING_COLABORADORES_LIST(state, payload) {
      state.loadingColaboradoresList = payload;
    },
  },
};
