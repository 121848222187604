import eSocialService from '@/services/eSocialService.js';
import { definePaginationQuery } from '@/utils/pagination';

function setDefaultState() {
  return {
    cboSearchTerm: '',
    cboSearchTableLoading: false,
    cboSearchTableItems: [],
    cboSearchTableItemsTotal: 0,
    cboSearchLoadingExport: false,
    cboSearchPagination: {
      itemsPerPage: 500,
      page: 1,
      sortBy: [],
    },
    cboSearchScheduleReport: false
  };
}

export default {
  namespaced: true,

  state: setDefaultState(),

  getters: {
    getCboSearchTableLoading(state) {
      return state.cboSearchTableLoading;
    },
    getCboSearchTableItems(state) {
      return state.cboSearchTableItems;
    },
    getCboSearchTableItemsTotal(state) {
      return state.cboSearchTableItemsTotal;
    },
    getCboSearchTerm(state) {
      return state.cboSearchTerm;
    },
    getCboSearchLoadingExport(state) {
      return state.cboSearchLoadingExport;
    },
    getCboSearchScheduleReport(state) {
      return state.cboSearchScheduleReport
    }
  },

  actions: {
    async fetchCboSuggestion({ commit, state }, payload) {
      commit('SET_CBO_SEARCH_TABLE_LOADING', true);
      commit('SET_CBO_SEARCH_TERM', payload);

      if ([undefined, null, ''].includes(payload)) {
        commit('SET_CBO_SEARCH_TABLE_LOADING', false);
        return;
      }
      try {
        let pagination = definePaginationQuery(state.cboSearchPagination);
        let data = (await eSocialService.cboSearch.getSearchResults(payload, pagination)).data;

        commit('SET_CBO_SEARCH_TABLE_ITEMS_TOTAL', data.totalElements);
        commit('SET_CBO_SEARCH_TABLE_ITEMS', data.content);
      } catch (e) {
        console.error('Error fetching data: ', e);
      }
      commit('SET_CBO_SEARCH_TABLE_LOADING', false);
    },
    setCboSearchLoadingExportButton({commit}, payload) {
      commit('SET_CBO_SEARCH_LOADING_EXPORT', payload);
    },
    async sendPDF({commit}, payload) {
      try {
        let response = await eSocialService.cboSearch.exportCboSearch(payload);
        commit('SET_CBO_SEARCH_SCHEDULE_REPORT', response.status === 202);
      } catch (e) {
        console.error('Error exporting data: ', e);
        commit('SET_CBO_SEARCH_LOADING_EXPORT', false);
      }
      commit('SET_CBO_SEARCH_LOADING_EXPORT', false);
    },
  },

  mutations: {
    SET_CBO_SEARCH_TABLE_LOADING(state, payload) {
      state.cboSearchTableLoading = payload;
    },
    SET_CBO_SEARCH_TABLE_ITEMS(state, payload) {
      state.cboSearchTableItems = payload;
    },
    SET_CBO_SEARCH_TABLE_ITEMS_TOTAL(state, payload) {
      state.cboSearchTableItemsTotal = payload;
    },
    SET_CBO_SEARCH_TERM(state, payload) {
      state.cboSearchTerm = payload;
    },
    SET_CBO_SEARCH_LOADING_EXPORT(state, payload) {
      state.cboSearchLoadingExport = payload;
    },
    SET_CBO_SEARCH_SCHEDULE_REPORT(state, payload) {
      state.cboSearchScheduleReport = payload;
    }
  },
};
