import certidaoService from '@/services/certidaoService.js';
import totalizadorService from '@/services/totalizadorService.js';
import gedService from '@/services/gedService.js';
import UserService from '@/services/userService.js';
import { cloneDeep, indexOf } from 'lodash';
import defaultScreen from '@/assets/json/userDefault.json';
//import AdminService from '@/services/adminService.js';

export default {
  namespaced: true,
  state: {
    users: [],
    showModal: false,
    token: '',
    refreshToken: '',
    error: '',
    authorities: [],
    getModuloMessage: '',
    messageLogin: '',
    thumbnail: '',
    user: {},
    pendency: false,
    totalizers: false,
    menus: [],
    adminMenus: [],
    hasMenuCedido: false,
  },
  getters: {
    userAuthorities(state) {
      return state.authorities;
    },
    hasMenuCedido(state) {
      return state.hasMenuCedido;
    },
    getMenus(state) {
      return state.menus;
    },
    getAdminMenus(state) {
      return state.adminMenus;
    },
    userInfo(state) {
      return state.user;
    },
    showModal(state) {
      return state.showModal;
    },
    token(state) {
      return state.token;
    },
    roles(state) {
      return state.authorities;
    },
    getModulo(state) {
      return state.getModuloMessage;
    },
    getMessageLogin(state) {
      return state.messageLogin;
    },
    getUserDetails(state) {
      return state.user;
    },
    getUserThumbnail(state) {
      return state.thumbnail;
    },
    getIfPendency(state) {
      return state.pendency;
    },
    hasTotalizers(state) {
      return state.totalizers;
    },
    getTfaEnabled(state) {
      return state.twoFactorAuthenticationEnabled;
    },
    getUserId(state) {
      return state.user.id;
    },
  },

  actions: {
    async getUser({ dispatch, commit }) {
      try {
        let { data } = await UserService.getUser();
        let roles = data.authorities;
        let layoutMenuForUser = [];
        let hasRole = roles.find((element) => element === 'ROLE_ADMIN');

        if (hasRole) {
          layoutMenuForUser = JSON.stringify(defaultScreen);
        } else {
          layoutMenuForUser = data.layoutMenu;
        }

        if (data) {
          let user = {
            id: data.id,
            firstName: data.firstName,
            lastName: data.lastName,
            username: data.username,
            cellPhone: data.cellPhone,
            emailAddress: data.emailAddress,
            authorities: data.authorities,
            thumbnailId: data.thumbnailId,
            twoFactorAuthenticationEnabled: data.twoFactorAuthenticationEnabled,
            layoutMenu: layoutMenuForUser,
            adminMenus: data.adminMenus,
          };

          let adminAsObject = JSON.parse(user.adminMenus);

          commit('SET_HASCEDIDOS', user.authorities.includes('ROLE_MENU_CESSAO'));

          // let menusAsObject = JSON.parse(user.layoutMenu);

          // commit('SET_MENUS', menusAsObject);
          commit('SET_ADMIN_MENUS', adminAsObject);
          commit('SET_AUTHORITIES', user.authorities);
          dispatch('getThumbnail', user.thumbnailId);

          return commit('SET_USER', user);
        }
      } catch (e) {
        dispatch('errorSubmit', e);
      }
    },

    async getThumbnail({ commit }, id) {
      let thumbnail = '';
      if (id != null) {
        let response = await gedService.profile.get(id);
        thumbnail = 'data:image/png;base64,' + response.data.base64;
      } else {
        thumbnail = require('@/assets/avatar.png');
      }

      commit('SET_THUMBNAIL', thumbnail);
    },

    async changeAvatar({ dispatch, commit }, payload) {
      let response = await gedService.profile.post(payload);
      let user = { thumbnailId: response.data };
      commit('SET_USERTHUMBNAILID', user.thumbnailId);
      if (response.status === 200) {
        let thumbnail = 'data:image/png;base64,' + payload.base64;

        commit('SET_THUMBNAIL', thumbnail);
        dispatch('saveUserAvatar', user);
        dispatch('changeModal', false);
      } else {
        const thumbnail = require('@/assets/avatar.png');
        commit('SET_THUMBNAIL', thumbnail);
      }
    },
    async saveUserAvatar(_, payload) {
      await UserService.update(payload);
    },

    async setMessage({ commit }, message) {
      commit('SET_GETMODULOMESSAGE', message);
    },

    setMessageLogin({ commit }, message) {
      commit('SET_MESSAGELOGIN', message);
    },

    async saveUser({ state, commit }, payload) {
      payload.thumbnailId = state.user.thumbnailId;
      payload.twoFactorAuthenticationEnabled = state.user.twoFactorAuthenticationEnabled;
      let user = cloneDeep(payload);
      commit('SET_USER', payload);
      delete user.authorities;
      delete user.username;
      delete user.id;
      await UserService.update(user);
    },
    changeModal({ commit }, payload) {
      commit('SET_MODAL', payload);
    },
    async updatedPassword(payload) {
      await UserService.updatePassword(payload);
    },

    async signIn({ dispatch }, credentials) {
      dispatch('removeToken');
      this.loading = true;

      try {
        let { data } = await UserService.login(credentials);
        if (data) {
          return dispatch('setTokens', data);
        }
      } catch (e) {
        dispatch('errorSubmit', e);
      }
    },

    async loginTwofa({ dispatch }, accessCode) {
      dispatch('removeToken');
      this.loading = true;

      try {
        let { data } = await UserService.loginTwofa(accessCode);
        if (data) {
          return dispatch('setTokens', data);
        }
      } catch (e) {
        dispatch('errorSubmit', e);
      }
    },

    async getIsPendency({ commit }) {
      try {
        let { data } = await certidaoService.certidoes.getPendency();
        commit('SET_PENDENCY', data);
      } catch (e) {
        console.log(e);
        commit('SET_PENDENCY', false);
      }
    },

    async verifyNewTotalizers({ commit }) {
      try {
        let { data } = await totalizadorService.financial.hasNewTotalizers();
        commit('SET_TOTALIZERS', data);
      } catch (e) {
        console.log(e);
        commit('SET_TOTALIZERS', false);
      }
    },

    async confirmPendentsCndsMsg() {
      await certidaoService.certidoes.confirmPendentsCndsMsg();
    },

    removeToken() {
      localStorage.removeItem('access_Token');
      localStorage.removeItem('refresh_Token');
    },

    errorSubmit({ commit, dispatch }, e) {
      if (e.message.includes(503) || e.message.includes(500)) {
        let error = 'Serviço indisponível, tente em um momento';
        commit('SET_ERROR', error);
        let mess = '';
        dispatch('setMessageLogin', mess);
      } else if (e.message.includes(401) || e.message.includes(400)) {
        let error = e.response.data.errors[1] || 'Usuário ou senha inválidos';
        commit('SET_ERROR', error);
        let mess = '';
        dispatch('setMessageLogin', mess);
      } else if (e.message.includes(404)) {
        let error = e.response.data.errors[1] || 'Erro não mapeado';
        commit('SET_ERROR', error);
        let mess = '';
        dispatch('setMessageLogin', mess);
      } else if (e.message.includes(302)) {
        let error = e.response.data.errors[1] || 'Redirecionando...';
        commit('SET_ERROR', error);
        let mess = '';
        dispatch('setMessageLogin', mess);
      } else {
        commit('SET_ERROR', e.message);
        let mess = '';
        dispatch('setMessageLogin', mess);
      }
      commit('SET_TOKEN', null);
      commit('SET_REFRESHTOKEN', null);
      commit('SET_USER', null);
    },
    async setTokens({ dispatch, commit, state }, payload) {
      commit('SET_ERROR', null);
      commit('SET_TOKEN', payload.access_token);
      commit('SET_REFRESHTOKEN', payload.refresh_token);
      dispatch('getIsPendency');

      localStorage.setItem('access_Token', state.token);
      localStorage.setItem('refresh_Token', state.refreshToken);
      await dispatch('verifyNewTotalizers');

      return dispatch('getUser');
    },
    async getTfaEnabled({ dispatch }) {
      try {
        let { data } = await UserService.getUser();

        if (data) {
          return data.twoFactorAuthenticationEnabled;
        }
      } catch (e) {
        dispatch('errorSubmit', e);
      }
    },
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
    },

    SET_MODAL(state, payload) {
      state.showModal = payload;
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
    SET_REFRESHTOKEN(state, payload) {
      state.refreshToken = payload;
    },
    SET_ERROR(state, payload) {
      state.error = payload;
    },
    SET_ADMIN_MENUS(state, payload) {
      state.adminMenus = payload;
    },
    SET_MENUS(state, payload) {
      state.menus = payload;
    },
    SET_AUTHORITIES(state, payload) {
      state.authorities = payload;
    },
    SET_GETMODULOMESSAGE(state, payload) {
      state.getModuloMessage = payload;
    },
    SET_HASCEDIDOS(state, payload) {
      state.hasMenuCedido = payload;
    },
    SET_MESSAGELOGIN(state, payload) {
      state.messageLogin = payload;
    },
    SET_THUMBNAIL(state, payload) {
      state.thumbnail = payload;
    },
    SET_PENDENCY(state, payload) {
      state.pendency = payload;
    },
    SET_TOTALIZERS(state, payload) {
      state.totalizers = payload;
    },
    SET_USERTHUMBNAILID(state, payload) {
      state.user.thumbnailId = payload;
    },
  },
};
function json2array(json) {
  var result = [];
  var keys = Object.keys(json);
  keys.forEach(function (key) {
    result.push(json[key]);
  });
  return result;
}
