import axios from 'axios';

//Axios creates
const AxiosIndex = axios.create({
  baseURL: process.env.VUE_APP_API + '/index/',
  headers: {
    Accepted: 'application/json',
    'Content-Type': 'application/json',
  },
});
const AxiosGed = axios.create({
  baseURL: process.env.VUE_APP_API + '/ged/',
  headers: {
    Accepted: 'application/json',
    'Content-Type': 'application/json',
  },
});

const axiosEsocial = axios.create({
  baseURL: process.env.VUE_APP_API + '/esocial/',
  headers: {
    Accepted: ' application/json',
    'Content-Type': 'application/json',
  },
});

const axiosEcac = axios.create({
  baseURL: process.env.VUE_APP_API + '/ecac/',

  headers: {
    Accepted: 'application/json',
    'Content-Type': 'application/json',
  },
});

const axiosEcacMessages = axios.create({
  baseURL: process.env.VUE_APP_API + '/ecac-messages/',

  headers: {
    Accepted: 'application/json',
    'Content-Type': 'application/json',
  },
});

const axiosAuth = axios.create({
  baseURL: process.env.VUE_APP_API + '/auth/',
  headers: {
    Accepted: 'application/json',
    'Content-Type': 'application/json',
  },
});
const axiosAdmin = axios.create({
  baseURL: process.env.VUE_APP_API + '/admin/',
  headers: {
    Accepted: 'application/json',
    'Content-Type': 'application/json',
  },
});
const axiosInteraction = axios.create({
  baseURL: process.env.VUE_APP_API + '/interaction/',
  headers: {
    Accepted: 'application/json',
    'Content-Type': 'application/json',
  },
});

const axiosCertidao = axios.create({
  baseURL: process.env.VUE_APP_API + '/certificate/',
  headers: {
    Accepted: 'application/json',
    'Content-Type': 'application/json',
  },
});

const axiosTotalizadores = axios.create({
  baseURL: process.env.VUE_APP_API + '/financial/',
  headers: {
    Accepted: 'application/json',
    'Content-Type': 'application/json',
  },
});

const axiosPisCofins = axios.create({
  baseURL: process.env.VUE_APP_API + '/piscofins/', // TODO: Alterar quando integracao com o Eureka estiver funcionando
  headers: {
    Accepted: 'application/json',
    'Content-Type': 'application/json',
  },
});

const axiosNotification = axios.create({
  baseURL: process.env.VUE_APP_API + '/auth/',
  headers: {
    Accepted: 'application/json',
    'Content-Type': 'application/json',
  },
});

const axiosVersion = axios.create({
  baseURL: process.env.VUE_APP_API + '/app/version',
  headers: {
    Accepted: 'application/json',
    'Content-Type': 'application/json',
  },
});

const axiosCrf = axios.create({
  baseURL: process.env.VUE_APP_API + '/crf/',
  headers: {
    Accepted: 'application/json',
    'Content-Type': 'application/json',
  },
});

const axiosWarSmartSolution = axios.create({
  baseURL: process.env.VUE_APP_API + '/esocial/wss/',
  headers: {
    Accepted: ' application/json',
    'Content-Type': 'application/json',
  },
});

const axiosDocuments = axios.create({
  baseURL: process.env.VUE_APP_API + '/documents/',
  headers: {
    Accepted: ' application/json',
    'Content-Type': 'application/json',
  },
});

axiosTotalizadores.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_Token');

    config.headers.ipAddress = localStorage.getItem('ipAddress');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosCertidao.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_Token');

    config.headers.ipAddress = localStorage.getItem('ipAddress');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

AxiosGed.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_Token');

    config.headers.ipAddress = localStorage.getItem('ipAddress');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

AxiosIndex.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_Token');

    config.headers.ipAddress = localStorage.getItem('ipAddress');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);
axiosAdmin.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_Token');

    config.headers.ipAddress = localStorage.getItem('ipAddress');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);
axiosEsocial.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_Token');

    config.headers.ipAddress = localStorage.getItem('ipAddress');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);
axiosEcac.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_Token');

    config.headers.ipAddress = localStorage.getItem('ipAddress');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosAuth.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_Token');

    config.headers.ipAddress = localStorage.getItem('ipAddress');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },

  (error) => Promise.reject(error),
);
axiosInteraction.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_Token');

    config.headers.ipAddress = localStorage.getItem('ipAddress');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosPisCofins.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_Token');

    config.headers.ipAddress = localStorage.getItem('ipAddress');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosCrf.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_Token');

    config.headers.ipAddress = localStorage.getItem('ipAddress');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosEcacMessages.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_Token');

    config.headers.ipAddress = localStorage.getItem('ipAddress');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosWarSmartSolution.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_Token');

    config.headers.ipAddress = localStorage.getItem('ipAddress');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);
axiosDocuments.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_Token');

    config.headers.ipAddress = localStorage.getItem('ipAddress');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export {
  AxiosIndex,
  axiosEsocial,
  axiosEcac,
  axiosEcacMessages,
  axiosAuth,
  axiosAdmin,
  AxiosGed,
  axiosInteraction,
  axiosCertidao,
  axiosTotalizadores,
  axiosPisCofins,
  axiosNotification,
  axiosVersion,
  axiosCrf,
  axiosWarSmartSolution,
  axiosDocuments,
};
