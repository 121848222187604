<template>
  <v-card>
    <router-view />
  </v-card>
</template>

<script>
import Theme from '@/mixins/Theme';


export default {
  name: 'App',

  mixins: [Theme],

  data: () => ({}),

  mounted() {},

  methods: {},
};
</script>

<style scoped>
.v-card {
  padding: 0;
}
</style>
