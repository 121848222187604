import notificationService from '@/services/notificationServices.js';

export default {
  namespaced: true,

  state: {
    validPassword: false,
    showToastCertidoes: {},
    showToastTotalizers: {},
  },

  getters: {
    getValidPassword(state) {
      return state.validPassword;
    },

    getShowToastCertidoes(state) {
      return state.showToastCertidoes;
    },
    getShowToastTotalizers(state) {
      return state.showToastTotalizers;
    },
  },

  actions: {
    async wasLast3Passwords1({ commit }, data) {
      try {
        let response = await notificationService.wasLast3Passwords(data);
        commit('SET_VALID_PASSWORD', Object.freeze(response.data));
      } catch (e) {
        console.log(e);
      }
    },

    changeShowToastCertidoes({ commit }, data) {
      commit('SET_SHOW_TOAST_CERTIDOES', data);
    },
    changeShowToastTotalizers({ commit }, data) {
      commit('SET_SHOW_TOAST_TOTALIZERS', data);
    },
  },

  mutations: {
    SET_VALID_PASSWORD(state, payload) {
      state.validPassword = payload;
    },

    SET_SHOW_TOAST_CERTIDOES(state, payload) {
      state.showToastCertidoes = payload;
    },
    SET_SHOW_TOAST_TOTALIZERS(state, payload) {
      state.showToastTotalizers = payload;
    },
  },
};
