import Vue from 'vue';
import Vuex from 'vuex';

import cadastrosGerenciais from './modules/cadastros-gerenciais';
import dashboard from './modules/dashboardModule';
import ecac from './modules/ecacModule';
import user from './modules/userModule';
import usuarios from './modules/usuariosModule';
import esocial from './modules/esocial';
import indicadores from './modules/indicadoresModule';
import notifications from './modules/notificationsModule';
import routerModule from './modules/routerModule';
import wss from './modules/wssModule';
import egov from './modules/eGovModule';
import documents from './modules/documentsModule';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    esocial,
    dashboard,
    ecac,
    user,
    usuarios,
    cadastrosGerenciais,
    indicadores,
    notifications,
    routerModule,
    wss,
    egov,
    documents,
  },
});

export default store;
