import eSocialService from '@/services/eSocialService.js';
import AdminServices from '@/services/adminServices.js';
import UserService from '@/services/userService.js';
import { definePaginationQuery } from '@/utils/pagination';
import groupBy from 'lodash/groupBy';
import { cloneDeep } from 'lodash';

function filtersDefaultState() {
  return {
    afastados: false,
    inativos: false,
    cnpj: [],
    period: [],
    regimePrevidenciario: [],
    category: [],
    motivoDeAfastamento: [],
    role: [],
    cbo: [],
  };
}

function totalAfastamentoGraphDefaultState() {
  return [
    {
      title: 'Total de afastamentos',
      percentual: 0,
      total: 0,
      color: '#E5D545',
      colorLegend: '#807726',
    },
    {
      title: 'Total de colaboradores',
      percentual: 0,
      total: 0,
      color: '#442d96',
      colorLegend: '#442d96',
    },
  ];
}

function paginationDefaultState() {
  return {
    itemsPerPage: 50,
    page: 1,
    sortBy: [],
  };
}
function maskCnpj(item) {
  let mascara = item.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

  return mascara;
}

function getOnlyItemIdsArray(items) {
  let ids = [];
  items.forEach((item) => {
    if (![undefined, null].includes(item.itemId)) {
      ids.push(item.itemId);
    }
  });
  return ids;
}

function mapFiltersDataForRequest(filters) {
  let requestData = {};
  if (![undefined, null].includes(filters)) {
    requestData.activeCollaboratorsToggle = filters.inativos;
    requestData.retiredCollaboratorsToggle = filters.afastados;
    requestData.startDate = filters.period[0];
    requestData.endDate = filters.period[1];
    requestData.categoryCodeList = getOnlyItemIdsArray(filters.category);
    requestData.codMotAfastList = getOnlyItemIdsArray(filters.motivoDeAfastamento);
    requestData.cnpjList = getOnlyItemIdsArray(filters.cnpj);
    requestData.cboCodeList = getOnlyItemIdsArray(filters.cbo);
    requestData.nmCargoList = getOnlyItemIdsArray(filters.role);
    requestData.tpRegPrevList = getOnlyItemIdsArray(filters.regimePrevidenciario);
    if (![undefined, null].includes(filters.filterTerm)) {
      requestData.filterTerm = filters.filterTerm;
    }
  }

  return requestData;
}

export default {
  namespaced: true,
  state: {
    loadingGraphs: true,
    dataGraphs: [],
    filtersSaved: filtersDefaultState(),
    totalAfastamentoGraph: totalAfastamentoGraphDefaultState(),
    afastamentosPorCategoriaGraphs: [],
    motivosDeAfastamentoGraphs: [],
    loadingColaboradoresList: true,
    colaboradoresList: [],
    totalColaboradoresList: 0,
    colaboradoresPagination: paginationDefaultState(),
    selectCnpj: [],
    selectCategory: [],
    selectRegimePrevidenciario: [],
    selectMotivoDeAfastamento: [],
    selectRole: [],
    selectCbo: [],
    lastProcessData: '',
    userId: null,
    loadingFilters: true,
    loadingCnpjFilter: true,
    scheduleReport: false,
  },

  getters: {
    getLoadingGraphs(state) {
      return state.loadingGraphs;
    },
    getFiltersSaved(state) {
      return state.filtersSaved;
    },
    getTotalAfastamentoGraph(state) {
      return state.totalAfastamentoGraph;
    },
    getAfastamentosPorCategoriaGraphs(state) {
      return state.afastamentosPorCategoriaGraphs;
    },
    getMotivosDeAfastamentoGraphs(state) {
      return state.motivosDeAfastamentoGraphs;
    },
    getColaboradoresList(state) {
      return state.colaboradoresList;
    },
    getColaboradoresPagination(state) {
      return state.colaboradoresPagination;
    },
    getLoadingColaboradoresList(state) {
      return state.loadingColaboradoresList;
    },
    getTotalColaboradoresList(state) {
      return state.totalColaboradoresList;
    },
    getSelectCnpj(state) {
      return state.selectCnpj;
    },
    getSelectCategory(state) {
      return state.selectCategory;
    },
    getSelectRegimePrevidenciario(state) {
      return state.selectRegimePrevidenciario;
    },
    getSelectMotivoDeAfastamento(state) {
      return state.selectMotivoDeAfastamento;
    },
    getSelectRole(state) {
      return state.selectRole;
    },
    getSelectCbo(state) {
      return state.selectCbo;
    },
    getAfastadosEInativosPagination(state) {
      return state.afastadosEInativosPagination;
    },
    getLastProcessData(state) {
      return state.lastProcessData;
    },
    getLoadingFilters(state) {
      return state.loadingFilters;
    },
    getLoadingCnpjFilter(state) {
      return state.loadingCnpjFilter;
    },
    getScheduleReport(state) {
      return state.scheduleReport;
    },
  },

  actions: {
    setLoadingGraphs({ commit }, payload) {
      commit('SET_LOADING_GRAPHS', payload);
    },
    async fetchDataGraphs({ commit, dispatch }, payload) {
      dispatch('setLoadingGraphs', true);
      let requestData = mapFiltersDataForRequest(payload);

      try {
        const data = (await eSocialService.afastadosEInativos.getDashboard(requestData)).data;
        commit('SET_DATA_GRAPHS', data);
        dispatch('setTotalAfastamentoGraph', data.activeRetiredProportion);
        dispatch('setAfastamentosPorCategoriaGraphs', {
          data: data.categoryProportion,
          total: data.activeRetiredProportion.retiredCount + data.activeRetiredProportion.inactiveCount,
        });
        dispatch('setMotivosDeAfastamentoGraphs', {
          data: data.codMotAfastProportion,
          total: data.activeRetiredProportion.retiredCount + data.activeRetiredProportion.inactiveCount,
        });
        dispatch('setLoadingGraphs', false);
      } catch (e) {
        console.error('Error at fetchDataGraphs: ', e);
        dispatch('setTotalAfastamentoGraph', { inactiveCount: 0, retiredCount: 0 });
        dispatch('setAfastamentosPorCategoriaGraphs', {
          data: [],
          total: 0,
        });
        dispatch('setMotivosDeAfastamentoGraphs', {
          data: [],
          total: 0,
        });
        commit('SET_DATA_GRAPHS', []);
        dispatch('setLoadingGraphs', false);
      }
    },
    applyFilters({ commit, dispatch }, payload) {
      commit('SET_FILTERS_SAVED', payload.filters);
      dispatch('fetchDataGraphs', payload.filters);
      dispatch('fetchDataColaboradoresList', payload.filterTableTerm);
    },
    clearFilters({ commit, dispatch }, payload) {
      commit('SET_FILTERS_SAVED', filtersDefaultState());
      dispatch('fetchDataGraphs', filtersDefaultState());
      dispatch('fetchDataColaboradoresList', payload.filterTableTerm);
    },
    setTotalAfastamentoGraph({ commit }, payload) {
      let totalInativos = payload.inactiveCount;
      let totalAfastados = payload.retiredCount;

      let totalColaboradores = totalInativos + totalAfastados;
      let percentualAfastados = 0;
      let percentualInativos = 0;
      if (!(totalColaboradores === 0)) {
        percentualAfastados = (totalAfastados / totalColaboradores) * 100;
        percentualInativos = (totalInativos / totalColaboradores) * 100;
      }

      let data = [
        {
          title: 'Total de afastamentos',
          percentual: percentualAfastados.toFixed(2),
          total: totalAfastados,
          color: '#E5D545',
          colorLegend: '#807726',
        },
        {
          title: 'Total de inativos',
          percentual: percentualInativos.toFixed(2),
          total: totalInativos,
          color: '#C73C44',
          colorLegend: '#C73C44',
        },
      ];

      commit('SET_TOTAL_AFASTAMENTO_GRAPH', data);
    },
    setAfastamentosPorCategoriaGraphs({ commit }, payload) {
      let totalAfastadosInativos = payload.total;
      let data = [];

      if ([undefined, null].includes(payload.data)) {
        commit('SET_AFASTAMENTOS_POR_CATEGORIA_GRAPHS', data);
      } else {
        payload.data.forEach((category) => {
          let title = `${category.codeCategory} - ${category.category} (${category.categoryCount})`;
          let percentual = (category.categoryCount / totalAfastadosInativos) * 100;
          let color = '#E5D545';
          let colorLegend = '#807726';

          data.push([
            {
              title: title,
              percentual: percentual.toFixed(2),
              total: category.categoryCount,
              color: color,
              colorLegend: colorLegend,
            },
          ]);
        });
        commit('SET_AFASTAMENTOS_POR_CATEGORIA_GRAPHS', data);
      }
    },
    setMotivosDeAfastamentoGraphs({ commit }, payload) {
      let totalAfastadosInativos = payload.total;
      let data = [];
      if ([undefined, null].includes(payload.data)) {
        commit('SET_MOTIVOS_DE_AFASTAMENTO_GRAPHS', data);
      } else {
        payload.data.forEach((motive) => {
          let title = `${motive.codMotAfast} - ${motive.motivoAfastamento} (${motive.codMotAfastCount})`;
          let percentual = (motive.codMotAfastCount / totalAfastadosInativos) * 100;
          let color = '#E5D545';
          let colorLegend = '#807726';

          if (motive.codMotAfast === '06' || motive.codMotAfast === '34') {
            color = '#C73C44';
            colorLegend = '#962D33';
          }

          if (motive.codMotAfast === '15' || motive.codMotAfast === '16') {
            color = '#3C73C7';
            colorLegend = '#2D5796';
          }

          data.push([
            {
              title: title,
              percentual: percentual.toFixed(2),
              total: motive.codMotAfastCount,
              color: color,
              colorLegend: colorLegend,
            },
          ]);
        });
        commit('SET_MOTIVOS_DE_AFASTAMENTO_GRAPHS', data);
      }
    },

    async fetchDataColaboradoresList({ commit, dispatch, getters, state }, payload) {
      let filters = cloneDeep(state.filtersSaved);
      filters.filterTerm = payload;
      let requestData = mapFiltersDataForRequest(filters);

      let paginationObject = getters.getColaboradoresPagination;
      let pagination = definePaginationQuery(paginationObject);

      dispatch('setLoadingColaboradoresList', true);

      try {
        const data = (await eSocialService.afastadosEInativos.getTable(requestData, pagination)).data;

        data.content.forEach((item, index) => {
          item.id_column = index + 1;
        });

        commit('SET_COLABORADORES_LIST', data.content);
        let totalElements = data.totalElements;
        commit('SET_TOTAL_COLABORADORES', totalElements);
        dispatch('setLoadingColaboradoresList', false);
      } catch (e) {
        commit('SET_COLABORADORES_LIST', []);
        commit('SET_TOTAL_COLABORADORES', 0);
        dispatch('setLoadingColaboradoresList', false);
        console.error('Error at get collaborators data:', e);
      }
    },

    setColaboradoresPagination({ commit, state, dispatch }, payload) {
      let isSamePagination =
        payload.pagination.itemsPerPage === state.colaboradoresPagination.itemsPerPage &&
        payload.pagination.page === state.colaboradoresPagination.page &&
        payload.pagination.sortBy === state.colaboradoresPagination.sortBy
          ? true
          : false;
      commit('SET_COLABORADORES_PAGINATION', payload.pagination);
      if (!isSamePagination) {
        dispatch('fetchDataColaboradoresList', payload.term);
      }
    },
    setLoadingColaboradoresList({ commit }, payload) {
      commit('SET_LOADING_COLABORADORES_LIST', payload);
    },
    setTotalColaboradoresList({ commit }, payload) {
      commit('SET_TOTAL_COLABORADORES_LIST', payload);
    },

    async fetchSelectCnpj({ state, dispatch, commit }) {
      dispatch('setLoadingCnpjFilter', true);
      if (![undefined, null].includes(state.userId)) {
        dispatch('getCompanies', state.userId);
      } else {
        try {
          let { data } = await UserService.getUser();
          let userId = data.id;

          commit('SET_USER_ID', userId);
          dispatch('getCompanies', userId);
        } catch (e) {
          console.error('Error at fetchSelectCnpj: ', e);
        }
      }
    },

    async getCompanies({ commit, dispatch }, payload) {
      try {
        let companies = [];
        let response = await AdminServices.userEstablishment.get(payload);
        let results = response.data;

        if (results && results.length > 0) {
          let establishments = [];
          results.forEach((item) => {
            establishments.push(item.establishment);
          });

          let groups = groupBy(establishments, 'socialName');

          groups = Object.entries(groups).map(([key, value]) => ({
            company: key,
            establishments: value,
          }));
          var groupId = 1;
          groups.forEach((item) => {
            let group = item.company;
            let groupItems = [];
            item.establishments.forEach((innerItem) => {
              let newGroupItem = {
                itemTitle: innerItem.socialName,
                itemSubtitle: maskCnpj(innerItem.details.cnpj),
                groupId: groupId,
                itemId: innerItem.details.cnpj,
              };
              groupItems.push(newGroupItem);
            });

            companies.push({
              groupTitle: group,
              groupId: groupId,
              items: groupItems,
            });
            groupId++;
          });
        }
        commit('SET_SELECT_CNPJ', companies);
        dispatch('setLoadingCnpjFilter', false);
      } catch (e) {
        console.error('Error at getCompanies: ', e);
      }
    },

    async fetchFilterSelectsData({ dispatch }) {
      dispatch('setLoadingFilters', true);
      try {
        const data = (await eSocialService.afastadosEInativos.getFilters()).data;
        dispatch('mapCategorySelect', data.groupCategoria);
        dispatch('mapMotivoAfastamentoSelect', data.groupTabelaMotivosAfastamento);
        dispatch('mapRegimePrevidenciariosSelect', data.groupRegimesPrevidenciarios);
        dispatch('mapCboSelect', data.groupCboCodeNmCargoList);
        dispatch('mapRoleSelect', data.groupCargoList);
        dispatch('setLoadingFilters', false);
      } catch (e) {
        console.error('Error at fetchFilterSelects: ', e);
      }
    },

    async exportTableAfastados({ commit }) {
      let response = await eSocialService.afastadosEInativos.export();
      commit('SET_RESULT_SCHEDULE_REPORT', response.status === 202);
    },

    mapCategorySelect({ commit }, payload) {
      commit('SET_SELECT_CATEGORY', payload);
    },
    mapMotivoAfastamentoSelect({ commit }, payload) {
      commit('SET_SELECT_MOTIVO_DE_AFASTAMENTO', payload);
    },
    mapRegimePrevidenciariosSelect({ commit }, payload) {
      commit('SET_SELECT_REGIME_PREVIDENCIARIO', payload);
    },
    mapCboSelect({ commit }, payload) {
      commit('SET_SELECT_CBO', payload);
    },
    mapRoleSelect({ commit }, payload) {
      commit('SET_SELECT_ROLE', payload);
    },
    setLoadingFilters({ commit }, payload) {
      commit('SET_LOADING_FILTERS', payload);
    },
    setLoadingCnpjFilter({ commit }, payload) {
      commit('SET_LOADING_CNPJ_FILTER', payload);
    },
  },

  mutations: {
    SET_DATA_GRAPHS(state, payload) {
      state.dataGraphs = payload;
    },
    SET_FILTERS_SAVED(state, payload) {
      state.filtersSaved = payload;
    },
    SET_TOTAL_AFASTAMENTO_GRAPH(state, payload) {
      state.totalAfastamentoGraph = payload;
    },
    SET_AFASTAMENTOS_POR_CATEGORIA_GRAPHS(state, payload) {
      state.afastamentosPorCategoriaGraphs = payload;
    },
    SET_MOTIVOS_DE_AFASTAMENTO_GRAPHS(state, payload) {
      state.motivosDeAfastamentoGraphs = payload;
    },
    SET_LOADING_GRAPHS(state, payload) {
      state.loadingGraphs = payload;
    },
    SET_COLABORADORES_LIST(state, payload) {
      state.colaboradoresList = payload;
    },
    SET_TOTAL_COLABORADORES(state, payload) {
      state.totalColaboradoresList = payload;
    },
    SET_COLABORADORES_PAGINATION(state, payload) {
      state.colaboradoresPagination = payload;
    },
    SET_LOADING_COLABORADORES_LIST(state, payload) {
      state.loadingColaboradoresList = payload;
    },
    SET_SELECT_CNPJ(state, payload) {
      state.selectCnpj = payload;
    },
    SET_SELECT_CATEGORY(state, payload) {
      state.selectCategory = payload;
    },
    SET_SELECT_REGIME_PREVIDENCIARIO(state, payload) {
      state.selectRegimePrevidenciario = payload;
    },
    SET_SELECT_MOTIVO_DE_AFASTAMENTO(state, payload) {
      state.selectMotivoDeAfastamento = payload;
    },
    SET_SELECT_ROLE(state, payload) {
      state.selectRole = payload;
    },
    SET_SELECT_CBO(state, payload) {
      state.selectCbo = payload;
    },
    SET_USER_ID(state, payload) {
      state.userId = payload;
    },
    SET_LOADING_FILTERS(state, payload) {
      state.loadingFilters = payload;
    },
    SET_LOADING_CNPJ_FILTER(state, payload) {
      state.loadingCnpjFilter = payload;
    },
    SET_RESULT_SCHEDULE_REPORT(state, payload) {
      state.scheduleReport = payload;
    },
  },
};
