// import Vue from "vue";
// import Vuex from "vuex";

// Vue.use(Vuex);
// const store = new Vuex.Store({
//   state: {
//     dados: [],
//   },
//   mutations: {
//     subirdados(state, payload) {
//       state.dados = payload;
//     },
//   },
//   getters: {
//     pegardados: state => {
//       return state.dados;
//     },
//   },
//   actions: {
//     subirdados(context, payload) {
//       context.commit("subirdados", payload);
//     },
//   },
// });

// export default store;
// //não estamos utilizando ainda.
