import store from '@/store';
import Vue from 'vue';
import App from './App.vue';
import './plugins';
import './filters';
import vuetify from './plugins/vuetify';
import router from './router';
import Vuelidate from 'vuelidate';

// import store from "./plugins/vuex";

Vue.config.productionTip = false;

Vue.use(Vuelidate);

export const eventBus = new Vue({
  store,
  router,
  vuetify,
  devServer: {
    proxy: 'http://localhost:9070',
    devServer: {
      headers: {
        'X-Frame-Options': 'SAMEORIGIN',
      },
    },
  },

  render: (h) => h(App),
}).$mount('#app');
