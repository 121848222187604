import AdminService from '@/services/adminServices.js';
import UserService from '@/services/userService.js';
import sortBy from 'lodash/sortBy';

export default {
  namespaced: true,

  state: {
    users: [],
    establishments: [],
    companies: [],
    userEstablishments: [],
    newEstablishments: [],
    loading: true,
    idUserUsed: '',
    userRoles: [],
    allRoles: [],
  },
  getters: {
    // async getUserDetails(state) {
    //   await dispatch('getUserEstablishment');
    //   return state.userEstablishments;
    // },
  },

  actions: {
    async getEstablishments({ dispatch, commit }, payload) {
      commit('SET_USERATUAL', payload.id);
      await dispatch('getUserEstablishment');
      await dispatch('getAllEstablishments');
    },

    async changeEstablishments({ commit, dispatch }, { value }) {
      commit('SET_NEWESTABLISHMENTS', value);
      dispatch('updateUserEstablishments');
    },

    async getUserEstablishment({ commit, state }) {
      let { data } = await AdminService.userEstablishment.get(state.idUserUsed);

      let userEstablishments = data.map((item) => item.establishment);

      userEstablishments = sortBy(userEstablishments, ['socialName', 'stateCode', 'city']);

      commit('SET_USERESTABLISHMENTS', userEstablishments);
    },

    async getAllEstablishments({ commit }) {
      const params = { page: 0, size: 100 };

      let response = await AdminService.establishment.get(params);
      let allEstablishments = response.data.content;

      let establishments = allEstablishments.filter(
        (v, i, a) => a.findIndex((t) => t.details.cnpj === v.details.cnpj) === i,
      );

      commit('SET_ESTABLISHMENTS', establishments);
    },

    async updateUserEstablishments({ state, commit }) {
      let { data } = await AdminService.userEstablishment.get(state.idUserUsed);
      let keys = [];
      await Object.entries(data).forEach(([key, entries]) => {
        keys.push(key);

        AdminService.userEstablishment.delete(entries.id);
      });

      state.newEstablishments.forEach((element) => {
        let user = {
          userId: state.idUserUsed,
          establishment: { id: element },
        };
        AdminService.userEstablishment.save(user);
      });

      commit('SET_USERESTABLISHMENTS', state.newEstablishments);
    },

    async getRoles({ commit, state }) {
      if (state.allRoles && state.allRoles.length > 0) return;

      let response = await UserService.role.get();

      let arrayprov = response.data;
      let listado = [];
      arrayprov.forEach((entries) => {
        listado.push('ROLE_' + entries);
      });

      commit('SET_ALLROLES', listado);
    },

    async changeRoles({ dispatch, state }, { value }) {
      let updateUser = {
        id: state.idUserUsed,
        authorities: value,
      };

      await UserService.update(updateUser);
      dispatch('getUsers');
      // dispatch('user/getUser', null, { root: true });
    },

    async getUsers({ commit }) {
      try {
        let { data } = await UserService.find();

        let listado = [];
        let keys = [];

        Object.entries(data).forEach(([key, entries]) => {
          keys.push(key);
          listado.push({
            id: entries.id,
            email: entries.emailAddress,
            username: entries.username,
            firstName: entries.firstName,
            lastName: entries.lastName,
            authorities: entries.authorities,
            establishment: entries.establishment,
          });
        });

        if (listado.length > 0) {
          commit('SET_USERS', listado);
          commit('SET_LOADING', false);
        } else {
          commit('SET_LOADING', true);
        }
      } catch (e) {
        commit('SET_LOADING', false);
      }
    },

    async saveUser({ dispatch }, payload) {
      await UserService.save(payload);
      dispatch('getUsers');
    },
  },

  mutations: {
    SET_USERS(state, payload) {
      state.users = payload;
    },
    SET_USERATUAL(state, payload) {
      state.idUserUsed = payload;
    },
    SET_ESTABLISHMENTS(state, payload) {
      state.establishments = payload;
    },
    SET_COMPANIES(state, payload) {
      state.companies = payload;
    },
    SET_USERESTABLISHMENTS(state, payload) {
      state.userEstablishments = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_NEWESTABLISHMENTS(state, payload) {
      state.newEstablishments = payload;
    },
    SET_USERROLES(state, payload) {
      state.userRoles = payload;
    },
    SET_ALLROLES(state, payload) {
      state.allRoles = payload;
    },
  },
};
