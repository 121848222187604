import esocialCedidos from './esocialCedidos';
import esocialMain from './esocialModule';
import esocialAfastadosEInativos from './esocialAfastadosEInativos';
import esocialHorariosEJornadas from './esocialHorariosEJornadas';
import esocialCbo from './esocialCbo';
import esocialRubricas from './esocialRubricas';
export default {
  namespaced: true,

  modules: { esocialCedidos, esocialMain, esocialAfastadosEInativos, esocialHorariosEJornadas, esocialCbo, esocialRubricas},
};
