export default {
  namespaced: true,

  state: {
    theme: { mode: false },
  },
  getters: {
    getTheme(state) {
      return state.theme;
    },
  },

  actions: {
    setTheme({ commit }, bool) {
      commit('SET_THEME', bool);
    },
  },

  mutations: {
    SET_THEME(state, payload) {
      state.theme.mode = payload;
    },
  },
};
