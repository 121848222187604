<script>
import cloneDeep from 'lodash/cloneDeep';

const ThemeType = {
  SYSTEM: 'SYSTEM',
  DARK: 'DARK',
  LIGHT: 'LIGHT',
};

export default {
  data() {
    return {
      themeApp: ThemeType.SYSTEM,
    };
  },

  computed: {
    isDarkTheme() {
      switch (this.themeApp) {
        // case ThemeType.SYSTEM:
        //   return this.getSystemIsDark();
        // case ThemeType.DARK:
        //   return true;
        case ThemeType.LIGHT:
          return false;
        default:
          return false;
      }
    },

    themeLabel() {
      if (this.isDarkTheme) return 'Tema claro';
      return 'Tema Escuro';
    },
  },

  created() {
    const theme = localStorage.getItem('theme');
    if (theme) this.themeApp = theme;
    this.handleThemeDark();
  },

  methods: {
    getSystemIsDark() {
      return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    },

    changeTheme() {
      if (this.isDarkTheme) this.themeApp = cloneDeep(ThemeType.LIGHT);
      else this.themeApp = cloneDeep(ThemeType.DARK);
      this.handleThemeDark();
    },

    handleThemeDark() {
      this.$vuetify.theme.dark = this.isDarkTheme;
      localStorage.setItem('theme', this.themeApp);
    },
  },
};
</script>
