import rubrics from '@/assets/json/rubrics';
// import categories from '@/assets/json/categories';
import eSocialService from '@/services/eSocialService.js';
import indexService from '@/services/indexService.js';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

export default {
  namespaced: true,

  state: {
    loadingDataIndex: true,
    filter: {
      establishments: [],
      categoryCode: [],
      categoryCodesForFilter: [],
      rubrics: [],
      periods: [],
    },
    companies: [],
    establishments: [],
    categoryCode: [],
    categoriaSelecionada: [],
    rubrics,
    periods: [],
    dataIndex: null,
    previousCategoriaSelecionada: [],
  },

  actions: {
    async init({ commit, dispatch, state }, filter) {
      commit('SET_PERIODS', mountPeriods());
      await dispatch('fetchCompanies');
      commit('SET_FILTER', !isEmpty(filter) ? filter : mountInitialFilter(state));
      dispatch('fetchIndex');
    },

    changeFilter({ commit, dispatch, state }, { prop, value }) {
      const filter = cloneDeep(state.filter);
      filter[prop] = value;
      commit('SET_FILTER', filter);

      dispatch('fetchIndexDebounce');
    },

    async changeCategory({ commit, state, dispatch }, payload) {
      const categoriesAdded = payload.filter((value) => !state.previousCategoriaSelecionada.includes(value), 0);

      const categoriesRemoved = state.previousCategoriaSelecionada.filter((value) => !payload.includes(value), 0);

      let previousCategoriaSelecionadaAlter = [];
      if (categoriesAdded.length > 0 && categoriesAdded[0].subtitle) {
        const lastValueEvent = payload[payload.length - 1];
        previousCategoriaSelecionadaAlter = cloneDeep(state.previousCategoriaSelecionada);
        if (lastValueEvent.subtitle) {
          state.categoryCode.filter((item) => {
            if (item.grupo == lastValueEvent.subtitle || item.subtitle == lastValueEvent.subtitle) {
              if (item.grupo || item.subtitle) {
                previousCategoriaSelecionadaAlter.push(item);
              }
            }
          });
        } else {
          previousCategoriaSelecionadaAlter.push(payload);
        }
        let onlyCodes = previousCategoriaSelecionadaAlter
          .map((item) => {
            if (item.codigo) {
              return item.codigo;
            }
          })
          .filter((item) => {
            if (item) return item;
          });

        dispatch('changeFilter', { prop: 'categoryCodeForFilter', value: previousCategoriaSelecionadaAlter });
        // dispatch('changeFilter', { prop: 'categoryCode', value: onlyCodes });
        commit('SET_CATEGORIASELECIONADA', onlyCodes);
        commit('SET_PREVIOUSCATEGORIASELECIONADA', previousCategoriaSelecionadaAlter);
      } else {
        let onlyCodes;
        if (!payload.subtitle) {
          onlyCodes = payload
            .map((item) => {
              if (item.codigo) {
                return item.codigo;
              }
            })
            .filter((item) => {
              if (item) return item;
            });
        }
        dispatch('changeFilter', { prop: 'categoryCodeForFilter', value: payload });
        // dispatch('changeFilter', { prop: 'categoryCode', value: onlyCodes });
        commit('SET_CATEGORIASELECIONADA', onlyCodes);
        commit('SET_PREVIOUSCATEGORIASELECIONADA', payload);
      }

      if (categoriesRemoved.length > 0 && categoriesRemoved[0].subtitle) {
        // payload = payload.filter((item) => item.group !== categoriesRemoved[0].subtitle);
        previousCategoriaSelecionadaAlter = state.previousCategoriaSelecionada.filter(
          (item) => item.grupo !== categoriesRemoved[0].subtitle,
        );

        let onlyCodes = previousCategoriaSelecionadaAlter
          .map((item) => {
            if (item.codigo) {
              return item.codigo;
            }
          })
          .filter((item) => {
            if (item) return item;
          });
        commit('SET_PREVIOUSCATEGORIASELECIONADA', previousCategoriaSelecionadaAlter);
        // dispatch('changeFilter', { prop: 'categoryCode', value: onlyCodes });
        commit('SET_CATEGORIASELECIONADA', onlyCodes);
        dispatch('changeFilter', { prop: 'categoryCodeForFilter', value: previousCategoriaSelecionadaAlter });
      } else if (categoriesRemoved.length > 0) {
        if (state.previousCategoriaSelecionada.length == 1 && state.previousCategoriaSelecionada[0].subtitle) {
          dispatch('changeFilter', { prop: 'categoryCodeForFilter', value: [] });
        }
      }
    },

    async fetchCompanies({ commit, dispatch }) {
      let { data } = await eSocialService.establishment.getByUserId();

      let companies = data.filter((v, i, a) => a.findIndex((t) => t.cnpjMatriz === v.cnpjMatriz) === i);
      companies = sortBy(companies, ['socialName', 'stateCode', 'city']);
      let establishments = data.filter((v, i, a) => a.findIndex((t) => t.cnpjFilial === v.cnpjFilial) === i);
      establishments = sortBy(establishments, ['socialName', 'stateCode', 'city', 'cnpjFilial']);

      commit('SET_COMPANIES', companies);
      commit('SET_ESTABLISHMENTS', establishments);
      await dispatch('getCategories');
    },
    async getCategories({ commit }) {
      const response = await indexService.indexProcess.getCategories();
      let dataReturned = response.data;

      commit('SET_CATEGORIES', dataReturned);
    },

    fetchIndexDebounce: debounce(({ dispatch }) => {
      return dispatch('fetchIndex');
    }, 500),

    async fetchIndex({ commit, state }) {
      commit('SET_LOADING_DATA_INDEX', true);

      try {
        commit('SET_DATA_INDEX', null);
        const { rubrics, establishments, periods } = state.filter;
        const categorySelected = state.categoriaSelecionada;
        if (rubrics.length != 0 && establishments.length != 0 && periods.length != 0) {
          // let categoryCodesSelecteds = getOnlyCode(categoryCode);
          const data = await indexService.evtremuns.get(rubrics, establishments, periods, categorySelected);

          data.graphOne = data.graphOne.map((item) => {
            let code = item.name.substring(0, 4);

            item.name = item.name.replace('-', `- ${state.rubrics.find((x) => x.codigo == code).nome}`);

            return item;
          });
          if (!isEmpty(data)) commit('SET_DATA_INDEX', data);
        } else return;
      } catch (e) {
        console.log(e);
      }

      commit('SET_LOADING_DATA_INDEX', false);
    },

    setLoadingToFalse({ commit }) {
      commit('SET_LOADING_DATA_INDEX', false);
    },
  },

  mutations: {
    SET_COMPANIES(state, payload) {
      state.companies = payload;
    },

    SET_ESTABLISHMENTS(state, payload) {
      state.establishments = payload;
    },

    SET_CATEGORIES(state, payload) {
      state.categoryCode = payload;
    },

    SET_PREVIOUSCATEGORIASELECIONADA(state, payload) {
      state.previousCategoriaSelecionada = payload;
    },
    SET_CATEGORIASELECIONADA(state, payload) {
      state.categoriaSelecionada = payload;
    },

    SET_FILTER(state, payload) {
      state.filter = payload;
    },

    SET_PERIODS(state, payload) {
      state.periods = payload;
    },

    SET_DATA_INDEX(state, payload) {
      state.dataIndex = payload;
    },
    SET_LOADING_DATA_INDEX(state, payload) {
      state.loadingDataIndex = payload;
    },
  },
};

function mountPeriods() {
  let periods = [];
  const currentYear = new Date().getFullYear();

  for (let year = 2010; year <= currentYear; year++) {
    periods.push(year);
  }

  return periods.reverse();
}

function mountInitialFilter(state) {
  const firstEstablishments = state.establishments[0];
  const establishments = state.establishments
    .filter((item) => item.cnpjMatriz === firstEstablishments.cnpjMatriz)
    .map((item) => item.cnpjFilial);
  return {
    establishments,
    rubrics: [state.rubrics[0].codigo],
    periods: [state.periods[1]],
  };
}
function getOnlyCode(payload) {
  let categoryCodeArray = [];
  if (payload) {
    payload.map((category) => {
      if (category.codigo) {
        categoryCodeArray.push(category.codigo);
      }
    });
  }

  return categoryCodeArray;
}
function checkIfObjectExists(value, payload) {
  let bool;
  if (value.subtitle) {
    bool = !payload.some((element) => (payload.subtitle = !element.subtitle));
  } else {
    bool = !payload.some((element) => (payload.codigo = !element.codigo));
  }
  // let bool = arr.some((element) => element.codigo == position.codigo || element.subtitle == position.subtitle);

  return bool;
}
