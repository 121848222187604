import ECacService from '@/services/ecacService.js';
import eSocialService from '@/services/eSocialService.js';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

export default {
  namespaced: true,

  state: {
    initComplete: false,
    loading: true,
    pagination: {
      page: 1,
      itemsPerPage: 5,
      sortBy: [],
      sortDesc: [],
      mustSort: false,
      multiSort: false,
    },
    filter: {
      cnpjEmpresa: [],
      anoApur: [],
    },
    totalDctf: 0,
    listDctf: [],
    companies: [],
    establishments: [],
    periods: [],
  },

  actions: {
    async init({ commit, dispatch, state }, filter) {
      commit('SET_PERIODS', mountPeriods());
      await dispatch('fetchCompanies');
      commit('SET_FILTER', !isEmpty(filter) ? filter : mountInitialFilter(state));
      await dispatch('fetchDctf');
      commit('SET_INIT_COMPLETE', true);
    },

    changeFilter({ commit, dispatch, state }, { prop, value }) {
      const filter = cloneDeep(state.filter);
      filter[prop] = value;
      commit('SET_FILTER', filter);
      commit('SET_LOADING', true);
      dispatch('fetchDctfDebounce');
    },

    changePagination({ commit, dispatch, state }, value) {
      commit('SET_PAGINATION', value);
      if (state.initComplete) dispatch('fetchDctf');
      // commit('SET_LOADING', true);
      // dispatch('fetchDctfDebounce');
    },

    async fetchCompanies({ commit }) {
      let { data } = await eSocialService.establishment.getByUserId();
      let companies = data.filter((v, i, a) => a.findIndex((t) => t.cnpjMatriz === v.cnpjMatriz) === i);
      companies = sortBy(companies, ['socialName', 'stateCode', 'city']);
      let establishments = data.filter((v, i, a) => a.findIndex((t) => t.cnpjFilial === v.cnpjFilial) === i);
      establishments = sortBy(establishments, ['socialName', 'stateCode', 'city']);

      commit('SET_COMPANIES', companies);
      commit('SET_ESTABLISHMENTS', establishments);
    },

    fetchDctfDebounce: debounce(({ dispatch }) => {
      return dispatch('fetchDctf');
    }, 500),

    async fetchDctf({ commit, state }) {
      commit('SET_LOADING', true);

      try {
        const filter = {
          ...state.filter,
          page: state.pagination.page - 1,
          size: state.pagination.itemsPerPage,
        };
        let response = await ECacService.findDCTF(filter);
        let ordered = sortBy(response.data.content, ['periodoApur']);
        this.items = response.data.content;

        commit('SET_LIST_DCTF', ordered);
        commit('SET_TOTAL_DCTF', response.data.totalElements);
      } catch (e) {
        commit('SET_LIST_DCTF', []);
        console.log(e);
      }

      commit('SET_LOADING', false);
    },
  },

  mutations: {
    SET_COMPANIES(state, payload) {
      state.companies = payload;
    },

    SET_ESTABLISHMENTS(state, payload) {
      state.establishments = payload;
    },

    SET_FILTER(state, payload) {
      state.filter = payload;
    },

    SET_PAGINATION(state, payload) {
      state.pagination = payload;
    },

    SET_TOTAL_DCTF(state, payload) {
      state.totalDctf = payload;
    },

    SET_PERIODS(state, payload) {
      state.periods = payload;
    },

    SET_LIST_DCTF(state, payload) {
      state.listDctf = payload;
    },
    SET_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_INIT_COMPLETE(state, payload) {
      state.initComplete = payload;
    },
  },
};

function mountPeriods() {
  let periods = [];
  const currentYear = new Date().getFullYear();

  for (let year = 2010; year <= currentYear; year++) {
    periods.push(year);
  }

  return periods.reverse();
}

function mountInitialFilter(state) {
  /*const firstEstablishments = state.establishments[0];
  const cnpjEmpresa = state.establishments
    .filter((item) => item.cnpjMatriz === firstEstablishments.cnpjMatriz)
    .map((item) => item.cnpjFilial);*/
  return {
    //cnpjEmpresa,
    anoApur: [state.periods[0]],
  };
}
