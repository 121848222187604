import eSocialService from '@/services/eSocialService.js';
import AdminServices from '@/services/adminServices.js';
import { definePaginationQuery } from '@/utils/pagination';
import groupBy from 'lodash/groupBy';
import { clone, cloneDeep } from 'lodash';

function mockFilter() {
  return {
    groupFamily: [
      {
        itemTitle: '6125 - Produtor de árvores frutíferas',
        itemId: '6125',
      },
      {
        itemTitle: '2631 - Ministros de culto, missionarios',
        itemId: '2631',
      },
      {
        itemTitle: '6220 - Trabalhador de apoio',
        itemId: '6220',
      },
      {
        itemTitle: '7842 - Alimentadores de linha de produção',
        itemId: '6220',
      },
    ],
    groupCbo: [
      {
        itemTitle: '6125-05 - Produtor de árvores frutiferas',
        itemId: '6125-05',
      },
      {
        itemTitle: '6125-10 - Produtor de espécies frutiferas',
        itemId: '6125-10',
      },
      {
        itemTitle: '6125-15 - Produtor de árvores frutiferas exóticas',
        itemId: '6125-15',
      },
    ],
    groupEducation: [
      {
        itemTitle: 'Analfabeto, inclusive o que, em',
        itemId: 'Analfabeto, inclusive o que, em',
      },
      {
        itemTitle: 'Até o 5° ano incompleto do ensino fundamental',
        itemId: 'Até o 5° ano incompleto do ensino fundamental',
      },
      {
        itemTitle: '5° ano completo do ensino fundamental',
        itemId: '5° ano completo do ensino fundamental',
      },
      {
        itemTitle: 'Do 6° ao 9° ano do ensino fundamental',
        itemId: 'Do 6° ao 9° ano do ensino fundamental',
      },
      {
        itemTitle: 'Ensino fundamental completo',
        itemId: 'Ensino fundamental completo',
      },
    ],
    groupProfessionalRegulatoryBody: [
      {
        itemTitle: 'Não aplicável',
        itemId: 'Não aplicável',
      },
      {
        itemTitle: 'Ordem dos Advogados do Brasil',
        itemId: 'Ordem dos Advogados do Brasil',
      },
      {
        itemTitle: 'Conselho Regional de Medicina',
        itemId: 'Conselho Regional de Medicina',
      },
      {
        itemTitle: 'Conselho Regional de Odontologia',
        itemId: 'Conselho Regional de Odontologia',
      },
      {
        itemTitle: 'Conselho Regional de Enfermagem',
        itemId: 'Conselho Regional de Enfermagem',
      },
    ],
    groupAmendedBy: [
      {
        itemTitle: 'heleny.custodio',
        itemId: 'heleny.custodio',
      },
      {
        itemTitle: 'ana.lucia',
        itemId: 'ana.lucia',
      },
      {
        itemTitle: 'alex.vieira',
        itemId: 'alex.vieira',
      },
    ],
  };
}

function mapFiltersDataForRequest(filters) {
  let requestData = {};

  if (![undefined, null].includes(filters)) {
    requestData.familyIds = getOnlyItemIdsArray(filters.family);
    requestData.occupationIds = getOnlyItemIdsArray(filters.occupation);
    requestData.educationIds = getOnlyItemIdsArray(filters.education);
    requestData.professionalRegulatoryBodyIds = getOnlyItemIdsArray(filters.professionalRegulatoryBody);
    requestData.isApprenticeshipQuota = filters.isApprenticeshipQuota;
    requestData.validityRange = [filters.startValidity, filters.endValidity];
    requestData.updatedAt = filters.updated;
    requestData.updatedBy = getOnlyItemIdsArray(filters.amendedBy);
    requestData.titleSynonymsFamily = filters.titleSynonymsFamily;
    if (![undefined, null].includes(filters.titleSynonymsFamily)) {
      requestData.titleSynonymsFamily = filters.titleSynonymsFamily + '$lk$$nml$';
    }
  }

  return requestData;
}
function getOnlyItemIdsArray(items) {
  let ids = [];
  items.forEach((item) => {
    if (![undefined, null].includes(item.itemId)) {
      ids.push(item.itemId);
    }
  });
  return ids;
}
function paginationDefaultState() {
  return {
    itemsPerPage: 50,
    page: 1,
    sortBy: [],
  };
}

function filtersDefaultState() {
  return {
    filtersSelect: {},
    family: [],
    occupation: [],
    education: [],
    isApprenticeshipQuota: false,
    isNotApprenticeshipQuota: false,
    professionalRegulatoryBody: [],
    period: [],
    updated: [],
    amendedBy: [],
    titleSynonymsFamily: null,
  };
}

function maskCnpj(item) {
  let mascara = item.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');

  return mascara;
}
export default {
  namespaced: true,

  state: {
    filtersSaved: filtersDefaultState(),
    loadingCboClassification: true,
    loadingFilters: true,
    cboClassificationList: [],
    filtersSelect: [],
    cboClassificationPagination: { itemsPerPage: 50, page: 1, sortBy: [] },
    scheduleReport: false,
    totalCboClassification: 0,
    titleSynonymsFamily: '',
  },
  getters: {
    getFiltersSelect(state) {
      return state.filtersSelect;
    },
    getDefaultFilterState() {
      return filtersDefaultState();
    },
    getFiltersSaved(state) {
      return state.filtersSaved;
    },
    getCboClassificationList(state) {
      return state.cboClassificationList;
    },
    getCboClassificationPagination(state) {
      return state.cboClassificationPagination;
    },
    getLoadingCboClassificationList(state) {
      return state.loadingCboClassification;
    },
    getCboClassificationTotal(state) {
      return state.totalCboClassification;
    },

    getLoadingFilters(state) {
      return state.loadingFilters;
    },
    getScheduleReport(state) {
      return state.scheduleReport;
    },
  },

  actions: {
    fetchTableByTerm({ dispatch, state, commit }, payload) {
      let filters = cloneDeep(state.filtersSaved);
      filters.titleSynonymsFamily = payload;
      commit('SET_FILTERS_SAVED', filters);
      dispatch('getCboList');
    },

    clearFilters({ dispatch, commit, state }) {
      let term = state.filtersSaved.titleSynonymsFamily;
      let filters = filtersDefaultState();
      filters.titleSynonymsFamily = term;
      commit('SET_FILTERS_SAVED', filters);
      dispatch('getCboList');
    },
    async getCboList({ commit, state }) {
      let filters = cloneDeep(state.filtersSaved);

      if (filters.isApprenticeshipQuota === true) {
        delete filters.isNotApprenticeshipQuota;
      } else if (filters.isNotApprenticeshipQuota === true) {
        filters.isApprenticeshipQuota = false;
        delete filters.isNotApprenticeshipQuota;
      } else if (filters.isApprenticeshipQuota === false && filters.isNotApprenticeshipQuota === false) {
        filters.isApprenticeshipQuota = null;
        delete filters.isNotApprenticeshipQuota;
      }

      let requestData = mapFiltersDataForRequest(filters);
      let paginationObject = state.cboClassificationPagination;
      let pagination = definePaginationQuery(paginationObject);

      if (pagination.includes('family')) {
        pagination = pagination.replaceAll('family', 'familyId');
      }
      if (pagination.includes('occupation')) {
        pagination = pagination.replaceAll('occupation', 'code');
      }

      commit('SET_LOADING_CBO_CLASSIFICATION_LIST', true);

      try {
        const { data } = await eSocialService.cboClassification.getCboList(requestData, pagination);
        data.content.forEach((item, index) => {
          item.id_column = index + 1;
        });
        commit('SET_CBO_CLASSIFICATION_LIST', data.content);
        let totalElements = data.totalElements;
        commit('SET_TOTAL_CBO_CLASSIFICATION_ITEMS', totalElements);
        commit('SET_LOADING_CBO_CLASSIFICATION_LIST', false);
      } catch (error) {
        commit('SET_CBO_CLASSIFICATION_LIST', []);
        commit('SET_TOTAL_CBO_CLASSIFICATION_ITEMS', 0);
        commit('SET_LOADING_CBO_CLASSIFICATION_LIST', false);

        console.error('AG_ERROR Error at cbo List:', error);
      }
    },
    async fetchSelectFilters({ state, dispatch, commit }) {
      const filter = mockFilter();
      const response = await eSocialService.cboClassification.getCboFilters();
      let filters = response.data;
      if (!filters.groupAmendedBy) {
        filters.groupAmendedBy = filter.groupAmendedBy;
      }
      commit('SET_FILTERS_SELECTS', filters);

      dispatch('getCboList');
      if (![undefined, null].includes(state.userId)) {
      } else {
        try {
        } catch (e) {
          console.error('AG_ERROR Error at fetchSelectCnpj: ', e);
        }
      }
    },
    applyFilters({ dispatch, commit, state }, payload) {
      let term = state.filtersSaved.titleSynonymsFamily;
      let filters = payload;
      filters.titleSynonymsFamily = term;
      commit('SET_FILTERS_SAVED', payload);
      dispatch('getCboList');
    },

    async exportCboClassifications({ commit, dispatch, state }, payload) {
      let filters = cloneDeep(state.filtersSaved);

      if (filters.isApprenticeshipQuota === true) {
        delete filters.isApprenticeshipQuota;
      } else if (filters.isNotApprenticeshipQuota === true) {
        filters.isApprenticeshipQuota = false;
        delete filters.isNotApprenticeshipQuota;
      } else if (filters.isApprenticeshipQuota === false && filters.isNotApprenticeshipQuota === false) {
        filters.isApprenticeshipQuota = null;
        delete filters.isNotApprenticeshipQuota;
      }

      let requestData = mapFiltersDataForRequest(filters);
      let responseDash = await eSocialService.cboClassification.exportCboList(requestData);
      commit('SET_RESULT_SCHEDULE_REPORT', responseDash.status === 202);
    },
    setCboClassificationPagination({ commit, state, dispatch }, payload) {
      let isSamePagination =
        payload.itemsPerPage === state.cboClassificationPagination.itemsPerPage &&
        payload.page === state.cboClassificationPagination.page &&
        payload.sortBy === state.cboClassificationPagination.sortBy
          ? true
          : false;

      commit('SET_INCIDENCE_CBO_CLASSIFICATION_PAGINATION', payload);
      if (!isSamePagination) {
        dispatch('getCboList', '');
      }
    },
  },

  mutations: {
    SET_INCIDENCE_CBO_CLASSIFICATION_PAGINATION(state, payload) {
      state.cboClassificationPagination = payload;
    },
    SET_LOADING_CBO_CLASSIFICATION_LIST(state, payload) {
      state.loadingCboClassification = payload;
    },
    SET_FILTERS_SAVED(state, payload) {
      state.filtersSaved = payload;
    },
    SET_USER_ID(state, payload) {
      state.userId = payload;
    },
    SET_CBO_CLASSIFICATION_LIST(state, payload) {
      state.cboClassificationList = payload;
    },

    SET_TOTAL_CBO_CLASSIFICATION_ITEMS(state, payload) {
      state.totalCboClassification = payload;
    },
    SET_RESULT_SCHEDULE_REPORT(state, payload) {
      state.scheduleReport = payload;
    },
    SET_LOADING_FILTERS(state, payload) {
      state.loadingFilters = payload;
    },
    SET_SELECT_CNPJ(state, payload) {
      state.selectCnpj = payload;
    },
    SET_FILTERS_SELECTS(state, payload) {
      state.filtersSelect = payload;
    },
  },
};
