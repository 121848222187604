import { axiosNotification } from '@/services/config.js';
import router from '@/router/index.js';

axiosNotification.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      
      if (error.response.status === 401) {
        localStorage.removeItem('access_Token');
        localStorage.removeItem('refresh_Token');
        localStorage.setItem('Error', 'Token vencido, por favor fazer login novamente.');
        router.push('/login');
      }
      return Promise.reject(error);
    },
  );

  export default {
    confirmNewPassword: (data) => {
      return axiosNotification.patch('/reset', data);
    },

    existsEmailAndSendToken: (email, isRegistration) => {
      return axiosNotification.get(`/reset?identificator=${email}&isRegistration=${isRegistration}`)
    },

    validateToken: (data) => {
      return axiosNotification.get(`/reset/token?token=${data.token}`)
    },

    byEmail: (data) => {
      return axiosNotification.patch(`/reset/${data.id}`, { 
        email: data.email, 
      });
    },

    bySms: (data) => {
      return axiosNotification.patch(`/reset/${data.id}`, { 
        phone: data.phone, 
      });
    },

    byQuestions: (data) => {
      return axiosNotification.patch(`/reset/${data.id}`, { 
        questionOne: data.questionOne, 
        questionTwo: data.questionTwo, 
        questionThree: data.questionThree
      });
    },

    wasLast3Passwords: (data) => {
        return axiosNotification.get(`/reset/valid/password?identificator=${data.identificator}&password=${data.password}`)
    },
    
    validateTokenCrypt: (data) => {
      return axiosNotification.get(`/reset/valid/token?accesscode=${data}`)
    },

    role: {
        get: () => {
          return axiosNotification.get('role');
        },
      },
  }